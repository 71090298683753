/**
 * User view module.
 * @module user
 */
"use strict";

import * as Api from '../core/api';
import * as Constants from '../core/constants';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Debug from '../core/debug';
import * as Session from '../core/session';
import * as State from '../core/state';

export class UserView {
    constructor() {
        this.viewName = 'UserView';
    }

    getUseableUserLevels() {
        const loginData = Session.getLoginData();
        const userLevel = loginData.accessLevel;
        let userLevels = [];

        const currentLevelIndex = Constants.USER_LEVELS.indexOf(userLevel);

        for(let i in Constants.USER_LEVELS){
            if(parseInt(i) < currentLevelIndex){
                userLevels.push(Constants.USER_LEVELS[i]);
            }
        }
        return userLevels;
    }

    /**
     * Render this users view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args, message='', isErrorMessage=false) {
        const loginData = Session.getLoginData();
        const userLevel = loginData.accessLevel;
        const userId = loginData.userId;
        let context = {
            'showUserList': false,
            'currentUserId': Session.getLoginData().userId,
            'userName': Session.getLoginData().userName,
            'userLevel': userLevel,
            'message': message,
            'isErrorMessage': isErrorMessage,
        };

        Api.getUsers().then((userList) => {
            let filteredList = [];
            for(const i in userList){
                if(
                    userList[i].id === userId ||
                    Helpers.equalOrLowerUserLevel(userList[i].level, userLevel)
                ){
                    filteredList.push(userList[i]);
                }
            }
            context.userList = filteredList;
            Gui.render('user.html', context);
        }).catch((e) => {
            Debug.log(e);
            Gui.render('user.html', context);
        });
    }

    /**
     * Render user screen
     * @param {number} id - The user id
     * @param {string} message - optional message
     */
    renderUser(id, message = '', isErrorMessage = false) {
        Api.getUser(id).then((user) => {
            let context = {
                'user': user,
                'currentUserId': Session.getLoginData().userId,
                'userLevels': this.getUseableUserLevels(),
                'message': message,
                'isErrorMessage': isErrorMessage,
            };
            Gui.render('user-edit.html', context);
        }).catch((err) => {
            Debug.log(err);
            return this.render(Helpers.createNewEvent(), {}, _('Error: failed to edit user'), true);
        });
    }

    /**
     * Render this users view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    edit(event, args) {
        if(Helpers.eventIsForm(event)) {
            const id = args.id;
            const name = Helpers.inputVal('name');
            const level = Helpers.inputVal('level');
            const password = Helpers.inputVal('password');
            const user = {
                'id': id,
                'name': name,
                'level': level,
                'password': password
            };

            Api.changeUser(user).then(() => {
                return this.renderUser(args.id, _('User updated successfully'));
            }).catch((err) => {
                Debug.log(err);
                return this.renderUser(args.id, _('Error: failed to update user'), true);
            });
        } else {
            return this.renderUser(args.id);
        }
    }

    /**
     * Delete user
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    deleteUser(event, args) {
        const loginData = Session.getLoginData();
        if(Helpers.eventIsForm(event)) {
            if(loginData.userId.toString() === args.id.toString()) {
                return this.renderUser(args.id, _('You can not delete your own user account'));
            }
            Api.deleteUser(args.id).then(() => {
                this.render(event, {}, _('User deleted successfully'));
            }).catch((err) => {
                return this.renderUser(args.id, _('Error deleting user'));
            });
        } else {
            Api.getUser(args.id).then((user) => {
                const context = {
                    'user': user
                };
                return Gui.render('user-delete.html', context);
            }).catch((err) => {
                Debug.log(err);
                return this.renderUser(args.id, _('Error: failed to delete user'), true);
            });
        }
    }

    /**
     * Create user
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    createUser(event, args) {
        if(Helpers.eventIsForm(event)){
            const name = Helpers.inputVal('name');
            const level = Helpers.inputVal('level');
            const password = Helpers.inputVal('password');

            if(
                typeof name !== 'string' ||
                typeof level !== 'string' ||
                typeof password !== 'string'
            ) {
                return this.render(args.id, _('Error: failed to create user'), '', true);
            }

            Api.createUser(name, level, password).then(() => {
                this.render(event, args, _('User created successfully'));
            }).catch((err) => {
                Debug.log(err);
                return this.render(args.id, _('Error: failed to create user'), '', true);
            });

        } else {
            let context = {
                'userLevels': this.getUseableUserLevels(),
            };
            Gui.render('user-new.html', context);
        }
    }
}
