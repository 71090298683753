/**
 * Permissions module.
 * @module module
 */

"use strict";

import * as Constants from './constants';
import * as Helpers from './helpers';
import * as Session from './session';
import * as State from './state';
import * as Debug from './debug';

export function canViewParameter(parameter){
    let userLevel = Session.getUserLevel();

    const accessLevel = parameter.accessLevels[userLevel];
    return ['R', 'R/W'].includes(accessLevel);
}

export function canEditParameter(parameter){
    let userLevel = Session.getUserLevel();

    const accessLevel = parameter.accessLevels[userLevel];
    return accessLevel === 'R/W';
}

/**
 * Check whether the current user can perform the supplied action.
 * @param {Object} iqObject - the IQ object
 * @param {string} actionName - the action name
 * @return boolean
 */
export function canPerformAction(iqObject, actionName) {
    let userLevel = Session.getUserLevel();

    const iqObjectType = Helpers.iqObjectType(iqObject);

    let allowed = false;

    if(iqObjectType === 'device') {
        allowed = Helpers.optChain(
            window.CONSTANTS.ACTIONS.device,
            iqObject.type.toString(),
            actionName,
            'permissions',
            userLevel
        );
    } else {
        allowed = Helpers.optChain(
            window.CONSTANTS.ACTIONS,
            iqObjectType,
            actionName,
            'permissions',
            userLevel
        );
    }

    if(Helpers.isUndefined(allowed)) {
        Debug.log(
            'Could not find permission for action ' + actionName +
            ' for IQ Object ' + iqObject.type +
            ' with user level ' + userLevel
        );
        return false;
    }

    return allowed;
}

function getGlobalPermissionForUserLevel(name, userLevel) {
    const globalPermissions = State.getGlobalPermissions();
    if(!globalPermissions.hasOwnProperty(name)) {
        return '';
    }
    const perm = globalPermissions[name];
    if(!perm.hasOwnProperty(userLevel)) {
        return '';
    }
    return perm[userLevel];
}

/**
 * Check whether the current user has read permissions on given permission
 * @param {string} name
 * @return boolean
 */
export function hasGlobalReadPermission(name) {
    const userLevel = Session.getUserLevel();
    const permValue = getGlobalPermissionForUserLevel(name, userLevel);
    return permValue === 'R' || permValue === 'R/W';
}

/**
 * Check whether the current user has read/write permissions on given
 * permission.
 * @param {string} name
 * @return boolean
 */
export function hasGlobalReadWritePermission(name) {
    const userLevel = Session.getUserLevel();
    const permValue = getGlobalPermissionForUserLevel(name, userLevel);
    return permValue === 'R/W';
}

/**
 * Check whether the current user has execute permissions on given permission
 * (NB: currently this is the same as hasGlobalReadWritePermission, since we
 * don't have a separate value for executable permissions yet)
 * @param {string} name
 * @return boolean
 */
export function hasGlobalExecutePermission(name) {
    return hasGlobalReadWritePermission(name);
}

/**
 * Check whether the current user can view the supplied action.
 * @param {Object} iqObject - the IQ object
 * @param {string} actionName - the action name
 * @return boolean
 */
export function canViewAction(iqObject, actionName) {
    let userLevel = Session.getUserLevel();

    for(let i = 0; i < iqObject.actions.length; i++){
        if(iqObject.actions[i].name === actionName) {
            return iqObject.actions[i].accessLevels[userLevel] === true;
        }
    }

    return false;
}

/**
 * Check whether the current user can view the supplied fieldbus setting
 * @param {string} objectType - iqObject type of this setting, i.e "fieldbus"
 *     or "clfb"
 * @param {string} settingName - the setting name
 * @return boolean
 */
export function canViewSetting(objectType, settingName) {
    const setting = Helpers.getSetting(objectType, settingName);
    if(setting === false) {
        return false;
    }

    const userLevel = Session.getUserLevel();

    if(!setting.accessLevels.hasOwnProperty(userLevel)) {
        return false;
    }

    const accessLevel = setting.accessLevels[userLevel];

    return accessLevel === 'R/W' || accessLevel === 'R';
}

/**
 * Check whether the current user can edit the supplied setting
 * @param {string} objectType - iqObject type of this setting, i.e "fieldbus"
 *     or "clfb"
 * @param {string} settingName - the setting name
 * @return boolean
 */
export function canEditSetting(objectType, settingName) {
    const setting = Helpers.getSetting(objectType, settingName);
    if(setting === false) {
        return false;
    }

    const userLevel = Session.getUserLevel();

    if(!setting.accessLevels.hasOwnProperty(userLevel)) {
        return false;
    }

    const accessLevel = setting.accessLevels[userLevel];

    return accessLevel === 'R/W';
}
