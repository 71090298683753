/**
 * Gui module.
 * @module gui
 */

"use strict";

import * as Constants from './constants';
import * as Debug from './debug';
import * as Help from './help';
import * as Helpers from './helpers';
import * as I8n from './i8n';
import * as Keyboard from './keyboard';
import * as Permissions from './permissions';
import * as Messages from './messages';
import * as Session from './session';
import * as Signals from './signals';
import * as State from './state';

let env;
let overrideScreenShown = false;

/**
 * Initialize the Gui system, set up Nunjucks.
 */
export function initGui(){
    env = nunjucks.configure({ autoescape: true });
    initFilters();
    Keyboard.initKeyboard();
    initScreenOverrides();
    initUpdateListener();
    initTime();

    if(Session.usingTouchScreen()) {
        $('body').addClass('using-touchscreen');
    }
}

/**
 * Initialize Nunjucks filters
 */
function initFilters() {
    env.addFilter('actionName', actionName);
    env.addFilter('isArray', (obj) => {return Array.isArray(obj);});
    env.addFilter('isDefined', Helpers.isDefined);
    env.addFilter('parameterName', parameterName);
    env.addFilter('trans', translateString);
    env.addFilter('isNaN', isNan);
    env.addGlobal('canEditParameter', Permissions.canEditParameter);
    env.addGlobal('canEditSetting', Permissions.canEditSetting);
    env.addGlobal('canPerformAction', Permissions.canPerformAction);
    env.addGlobal('canViewAction', Permissions.canViewAction);
    env.addGlobal('canViewParameter', Permissions.canViewParameter);
    env.addGlobal('canViewSetting', Permissions.canViewSetting);
    env.addGlobal('deviceIcon', deviceIcon);
    env.addGlobal('eventMessage', eventMessage);
    env.addGlobal('iqObjectType', Helpers.iqObjectType);
    env.addGlobal('isClfb', Helpers.isClfb);
    env.addGlobal('isFieldbus', Helpers.isFieldbus);
    env.addGlobal('isDevice', Helpers.isDevice);
    env.addGlobal('paramToString', paramToString);
    env.addGlobal('iq4Text', iq4Text);
    env.addGlobal('settingToString', settingToString);
    env.addGlobal('settingViewAsType', settingViewAsType);
    env.addGlobal('parameterIcon', parameterIcon);
    env.addGlobal('parameterUnit', parameterUnit);
    env.addGlobal('parameterValue', parameterValue);
    env.addGlobal('settingIcon', settingIcon);
    env.addGlobal('prettySettingName', prettySettingName);
    env.addGlobal('settingInputValue', settingInputValue);
    env.addGlobal('getLanguage', Session.getLanguage);
    env.addGlobal('renderHelpText', renderHelpText);
    env.addGlobal('showActionInGrid', showActionInGrid);
    env.addGlobal('showSettingInGrid', showSettingInGrid);
    env.addGlobal('getParameterGroup', getParameterGroup);
    env.addGlobal('getSettingGroup', getSettingGroup);
    env.addGlobal(
        'hasGlobalReadPermission',
        Permissions.hasGlobalReadPermission
    );
    env.addGlobal(
        'hasGlobalReadWritePermission',
        Permissions.hasGlobalReadWritePermission
    );
    env.addGlobal(
        'hasGlobalExecutePermission',
        Permissions.hasGlobalExecutePermission
    );
}

function isNan(x) {
    return isNaN(x);
}

/**
 * Translates given string into the currently selected language and returns it
 * as a safe string for usage inside templates
 */
function translateString(str) {
    return safeString(I8n.translate(str));
}

/**
 * Returns the pretty parameter name given the raw name
 * @param {string} name - The raw parameter name, i.e "PARAM_QID_CM_TINY_DEVICE_NAME"
 * @returns {string} The pretty parameter name, i.e "Device Name"
 */
export function parameterName(name){
    return safeString(I8n.getParameterName(name));
}

/**
 * Returns the pretty setting name given the raw setting
 * @param {string} objectType - this name's iq object type (i.e 'clfb' or 'fieldbus')
 * @param {string} name - The raw setting name, i.e "integralMax"
 * @returns {string} The pretty setting name, i.e "Integral Max"
 */
export function prettySettingName(objectType, name){
    if(name === '') {
        return '';
    }

    return iq4Text(objectType.toUpperCase() + '_' + name.toUpperCase());
}

/**
 * Returns the setting value for usage in input fields
 * @param {object} iqObject
 * @param {string} name - the setting name
 * @param {number} currentValue
 * @returns {String}
 */
export function settingInputValue(iqObject, name, currentValue) {
    const iqObjectType = Helpers.iqObjectType(iqObject);
    const setting = Helpers.getSetting(iqObjectType, name);


    let value = currentValue;

    let number = 0;
    if(typeof value === 'string') {
        number = parseFloat(value);
    } else if (typeof value === 'number') {
        number = value;
    }

    let decimals = 0;
    if(setting.decimals){
        decimals = Helpers.minimum(setting.decimals, 6);
    }

    let prettyNumber = number.toFixed(decimals).toString();

    if(isNaN(number)) {
        return '';
    }

    return safeString(prettyNumber);
}

/**
 * Returns the pretty action name given the raw name
 * @param {string} name - The raw action name, i.e "restore-factory"
 * @returns {string} The pretty parameter name, i.e "Restore factory settings"
 */
export function actionName(name){
    return safeString(I8n.getActionName(name));
}

/**
 * Returns the icon filename for the given parameter
 * @param {Object} parameter
 */
function parameterIcon(parameter){
    let name = parameter.name.toUpperCase();
    name = name.replace('_MNGR4', '');
    name = Helpers.replaceAll(name, ' ', '_');
    name = Helpers.replaceAll(name, '-', '_');
    if(window.CONSTANTS.ICONS.hasOwnProperty(name)){
        return 'images/icons/' + window.CONSTANTS.ICONS[name];
    }

    const last = parameter.name.split('_').reverse()[0];

    if(window.CONSTANTS.ICONS.hasOwnProperty(last)){
        return 'images/icons/' + window.CONSTANTS.ICONS[last];
    }
    Debug.log('Icon not found for parameter ' + name);
    return 'images/default-icon.svg';
}

/**
 * Returns the icon filename for the given device
 * @param {Object} device
 */
function deviceIcon(device=false){
    if(device === false) {
        return 'images/icon-device.svg';
    }

    if(Helpers.isFieldbus(device)){
        return 'images/icon-fieldbus.svg';
    }

    if(window.CONSTANTS.ICONS.hasOwnProperty('PID-' + device.type)) {
        return 'images/icons/' + window.CONSTANTS.ICONS['PID-' + device.type];
    }

    Debug.log('Icon not found for device ' + device.info);
    return 'images/icon-device.svg';
}

/**
 * Returns the unit for a given parameter
 * @param {object} param - the parameter object
 */
export function parameterUnit(param){
    return param.unit;
}

/**
 * Returns the parameter value for usage in input fields
 * @param {object} param - the parameter
 * @returns {String}
 */
export function parameterValue(param) {
    if(param.viewAs.type !== 'number') {
        return param.currentValue;
    }

    let value = param.currentValue;

    let number = 0;
    if(typeof value === 'string') {
        number = parseFloat(value);
    } else if (typeof value === 'number') {
        number = value;
    }

    if(isNaN(number)) {
        return '';
    }

    let decimals = 0;
    if(param.viewAs.decimals){
        decimals = Helpers.minimum(param.viewAs.decimals, 6);
    }

    let prettyNumber = number.toFixed(decimals).toString().replace(',','.');

    return safeString(prettyNumber);
}

/**
 * Returns a safe and pretty string for viewing this parameter value
 * @param {object} param - The parameter object
 * @param {string | number | boolean} value - The raw value
 * @returns {string} The pretty value
 */
export function paramToString(param, value, device=null){
    if(typeof value === 'undefined' || value === null){
        return '';
    }

    if(param.viewAs.type === 'segments') {
        let valueArray = [];
        let minWidth = 16;
        let output = '';

        if(param.name.endsWith('ENABLED_SEGMENTS')) {
            if(device !== null) {
                for(let p of device.parameters) {
                    if(p.name.endsWith('AVAILABLE_SEGMENTS')) {
                        minWidth = Helpers.numberToBitArray(p.maxValue).length;
                        break;
                    }
                }
            }
        }

        if(param.name.endsWith('AVAILABLE_SEGMENTS')) {
            minWidth = Helpers.numberToBitArray(param.maxValue).length;
        }

        valueArray = Helpers.numberToBitArray(value, minWidth);

        // Bitmasks are shown as little endian, but they're internally
        // processed as big endian, so we need to reverse them before showing
        valueArray.reverse();

        for(let i = 0; i < valueArray.length; i++) {
           if(valueArray[i] === true) {
               if(Session.usingTouchScreen()) {
                   output += 'X';
               } else {
                   output += '☒';
               }
           } else {
               output += '☐';
           }
        }

        return output;
    }

    let paramType = param.type;
    if(typeof value === 'number' && paramType !== 'number') {
        paramType = 'number';
    }

    switch(paramType){
        case 'boolean':
            if(value === '1' || value === 1 || value === 'true' || value === true){
                value = true;
            } else {
                value = false;
            }

            if(param.viewAs.type === 'yes/no'){
                return value ? _('Yes') : _('No');
            } else if(param.viewAs.type === 'on/off'){
                return value ? _('On') : _('Off');
            } else {
                if(value){
                    return safeString('<span class="icon-true">');
                } else {
                    return safeString('<span class="icon-false">');
                }
            }
            break;
        case 'number':
            if(param.viewAs.type === 'enum'){
                let index = value.toString();
                if(param.viewAs.enumValues.hasOwnProperty(index)){
                    return param.viewAs.enumValues[index];
                } else {
                    return _('Unknown');
                }
            }

            let number = 0;
            if(typeof value === 'string') {
                number = parseFloat(value);
            } else if (typeof value === 'number') {
                number = value;
            }

            let decimals = 0;
            if(param.viewAs.decimals){
                decimals = Helpers.minimum(param.viewAs.decimals, 6);
            }

            let prettyNumber = number.toFixed(decimals).toString();

            if(isNaN(number)) {
                return '';
            }

            if(param.unit && param.unit !== ''){
                prettyNumber = prettyNumber + ' ';
                prettyNumber = prettyNumber + param.unit;
            }
            return safeString(prettyNumber);
        case 'string':
            return safeString(value.toString());
        case 'datetime':
            if(value === null){
                return '';
            }

            let tmpDate = 0;
            if(typeof value === 'string') {
                tmpDate = Date.parse(value.replace('T', ' '));
            } else if(typeof value === 'number') {
                tmpDate = value;
            } else {
                return safeString(value.toString());
            }

            if(isNaN(tmpDate)) {
                if(typeof value === 'number') {
                    return safeString(value.toString());
                }
                return safeString(value);
            }

            return safeString(
                Helpers.formatDateTime(
                    tmpDate,
                    State.getTimeZoneOffset(),
                    State.get24HoursIsPreferred()
                )
            );
        default:
            Debug.log('Unknown parameter type: ' + param.type);
            return safeString(value.toString());
    }
    return value.toString();
}

/**
 * Returns the IQ4 Text description for the given setting or parameter
 * @param {object} name - The parameter or setting name
 * @returns {string} The IQ4 Text description
 */
export function iq4Text(name) {
    name = name.toUpperCase();
    if(window.CONSTANTS.IQ4_TEXTS.hasOwnProperty(name)) {
        const iq4Text = window.CONSTANTS.IQ4_TEXTS[name];
        if(iq4Text === null) {
            return '';
        } else {
            return I8n.translate(iq4Text);
        }
    }
    return '';
}


/**
 * Renders a given template with a context using Nunjucks and return the
 * generated string
 * @param {string} template - i.e "home.html"
 * @param {object} context - the context object used for rendering
 * @return {string}
 */
export function renderToString(template, context={}){
    return env.render(template, context);
}


/**
 * Render a given template with a context using Nunjucks, and put the
 * result in the content div
 * @param {string} template - i.e "home.html"
 * @param {object} context - the context object used for rendering. If the
 *     context object contains a "helpPath" attribute, then that's used to
 *     modify the help icon link in the header.
 */
export function render(template, context={}) {
    Debug.log('[GUI] Render ' + template);
    if(context.hasOwnProperty('helpPath')) {
        $('header.main div.icons a.help, div.menu a.help').attr(
            'href',
            Helpers.joinPaths(['/#/help', context.helpPath])
        );
    } else {
        $('header.main div.icons a.help, div.menu a.help').attr('href', Constants.DEFAULT_HELP_PATH);
    }

    $('div.container').html(env.render(template, context));
    window.scrollTo(0,0);
}

/**
 * Refreshes the list of states/modi and their counts in the header
 */
export function refreshStateList(){
    const devices = State.getDevices();
    const fieldbuses = State.getFieldbuses();
    const clfbs = State.getClfbs();

    let warnings = 0;
    let errors = 0;
    let running = 0;
    let standby = 0;
    let disabled = 0;
    let updating = 0;
    let unknown = 0;

    for(const i in devices){
        if(!devices.hasOwnProperty(i)) {
            continue;
        }

        const device = devices[i];
        if(device.status.warnings){
            warnings++;
        }
        if(device.status.errors){
            errors++;
        }
        switch(device.status.mode){
            case 'running':
                running++;
                break;
            case 'run':
                running++;
                break;
            case 'standby':
                standby++;
                break;
            case 'updating':
                updating++;
                break;
            case 'unknown':
                unknown++;
                break;
            case 'disabled':
                disabled++;
                break;
            case 'disconnected':
                disabled++;
                break;
        }
    }

    for(const i in fieldbuses) {
        if(!fieldbuses.hasOwnProperty(i)) {
            continue;
        }
        const fieldbus = fieldbuses[i];
        switch(fieldbus.status.mode) {
            case 'run':
            case 'running':
                running++;
                break;
            case 'waiting':
                standby++;
                break;
            case 'starting':
                standby++;
                break;
            case 'stopped':
                disabled++;
                break;
        }

        if(fieldbus.status.warnings){
            warnings++;
        }

        if(fieldbus.status.errors){
            errors++;
        }
    }

    for(const i in clfbs) {
        if(!clfbs.hasOwnProperty(i)) {
            continue;
        }

        const clfb = clfbs[i];
        if(clfb.status.warnings){
            warnings++;
        }
        if(clfb.status.errors){
            errors++;
        }

        switch(clfb.status.mode){
            // TODO: remove enabled when manager has dropped support for that
            // in favor of run/standby
            case 'enabled':
                running++;
                break;
            case 'running':
            case 'run':
                running++;
                break;
            case 'standby':
                standby++;
                break;
            case 'unknown':
                unknown++;
                break;
            case 'disabled':
                disabled++;
                break;
        }
    }

    const stateMap = {
        'error': errors,
        'warning': warnings,
        'running': running,
        'standby': standby,
        'disabled': disabled,
        'unknown': unknown,
    };

    for(const i in stateMap){
        if(!stateMap.hasOwnProperty(i)){
            continue;
        }

        if(i === 'unknown') {
            // We don't have a seperate representation of objects in an
            // "unknown" state
            continue;
        }

        const count = stateMap[i];
        $('div.status-list a.' + i).attr('data-count', count);
        $('div.status-list a.' + i + ' span.count').html(count);
    }
}

/**
 * Makes a given string safe for rendering
 * @param {string} str - i.e a rendered template
 * @returns {object} safe string
 */
export function safeString(str) {
    return new nunjucks.runtime.SafeString(String(str));
}

/**
 * Render a given string with a context using Nunjucks, and return
 * the result.
 * @param {string} template - i.e "<p>{{ greeting }} world</p>"
 * @param {object} context - the context object used for rendering, i.e {'greeting': 'Hello'}
 * @returns {string} rendered string
 */
export function renderString(template, context={}) {
    return env.renderString(template, context);
}

/**
 * 404 error page, shown when routing fails
 */
export function show404(){
    Debug.flagError('404 Page not found');
    render('404.html');
}

/**
 * Hide the current page and show the screensaver.
 */
export function showScreenSaver() {
    $('div.screensaver').show();
    $('div.content').hide();
}

/**
 * Hide the screensaver and shows the underlying page.
 */
export function hideScreenSaver() {
    $('div.content').show();
    $('div.screensaver').hide();
}

function getDeviceElement(uid){
    return $('.device[data-uid="' + uid + '"]');
}

function getParameterElement(deviceUid, parameterQid){
    return $('.parameter[data-id="' + deviceUid + '-' + parameterQid + '"]').not('form');
}

function getRawParameterElement(deviceUid, parameterQid){
    return $('section.parameter[data-uid="' + deviceUid + '"][data-qid="' + parameterQid + '"]');
}

/**
 * Change the displayed device mode for a given device.
 * @param {string} uid - the device uid
 * @param {string} mode - the new mode
 */
export function switchDeviceMode(uid, mode, hasWarnings=false, hasErrors=false) {
    let deviceElem = getDeviceElement(uid);
    if(deviceElem.length === 0){
        return;
    }
    let circle = $('span.circle', deviceElem);
    if(circle.length === 0){
        return;
    }
    circle.removeClass('unknown running standby updating disabled disconnected').addClass(mode);
    let span = $('span', circle);

    span.attr('class', '');
    switch(mode){
        case 'running':
            span.addClass('running');
            $('section.actions-list[data-id=' + uid + '] a.action.run-standby span, a.toggle-mode-small[data-param=' + uid + '] span')
                .removeClass('icon-play')
                .addClass('icon-pause');
            $('section.actions-list a.action.enable-disable span[data-id=' + uid + ']')
                .removeClass('icon-power')
                .addClass('icon-disable');
            break;
        case 'standby':
            span.addClass('standby');
            $('section.actions-list[data-id=' + uid + '] a.action.run-standby span, a.toggle-mode-small[data-param=' + uid + '] span')
                .removeClass('icon-pause')
                .addClass('icon-play');
            $('section.actions-list[data-id=' + uid + '] a.action.enable-disable span')
                .removeClass('icon-power')
                .addClass('icon-disable');
            break;
        case 'disconnected':
        case 'disabled':
            span.addClass('status-icon icon-disable');
            $('section.actions-list[data-id=' + uid + '] a.action.enable-disable span')
                .removeClass('icon-disable')
                .addClass('icon-power');
            break;
        case 'updating':
            span.addClass('updating');
            break;
        case 'unknown':
            span.addClass('unknown');
            break;
    }

    $('section.actions-list[data-id=' + uid + ']').attr('data-mode', mode);
}


/**
 * Change the displayed mode for a given fieldbus.
 * @param {string} uid - the fieldbus uid
 * @param {string} mode - the new mode
 */
export function switchFieldbusMode(uid, mode) {
    let fieldbusElem = getDeviceElement(uid);
    if(fieldbusElem.length === 0){
        return;
    }
    let circle = $('span.circle', fieldbusElem);
    if(circle.length === 0){
        return;
    }

    // Standby is not really a proper Fieldbus mode, but we use that as a span
    // class nevertheless
    const modes = ['disabled', 'running', 'starting', 'stopped', 'waiting', 'standby'];

    circle.removeClass(modes).addClass(mode);
    let span = $('span', circle);

    span.attr('class', '');
    const enableDisableButton = $('section.actions-list[data-id=' + uid + '] a.action.enable-disable span');
    switch(mode){
        case 'running':
        case 'run':
            span.addClass('running');
            break;
        case 'starting':
        case 'waiting':
            span.addClass('standby');
            break;
        case 'disabled':
            span.addClass('status-icon icon-disable');
            break;
        case 'stopped':
            span.addClass('status-icon icon-disable');
            break;
        default:
          break;
    }

    if(mode === 'disabled' || mode === 'stopped') {
        enableDisableButton.removeClass('icon-disable').addClass('icon-power');
    } else {
        enableDisableButton.removeClass('icon-power').addClass('icon-disable');
    }

    $('section.actions-list[data-id=' + uid + ']').attr('data-mode', mode);
}


/**
 * Change the displayed mode for a given clfb.
 * @param {string} uid - the clfb uid
 * @param {string} mode - the new mode
 */
export function switchClfbMode(uid, mode) {
    let clfbElem = getDeviceElement(uid);
    if(clfbElem.length === 0){
        return;
    }
    let circle = $('span.circle', clfbElem);
    if(circle.length === 0){
        return;
    }


    circle.removeClass('enabled disabled unknown standby running').addClass(mode);
    let span = $('span', circle);

    span.attr('class', '');
    const enableDisableButton = $('section.actions-list[data-id=' + uid + '] a.action.enable-disable span');
    switch(mode){
        case 'enabled':
        case 'run':
        case 'running':
            span.addClass('running');
            circle.removeClass('error');
            enableDisableButton.removeClass('icon-power').addClass('icon-disable');
            break;
        case 'standby':
            span.addClass('standby');
            enableDisableButton.removeClass('icon-power').addClass('icon-disable');
            break;
        case 'disabled':
            span.addClass('status-icon icon-disable');
            circle.removeClass('error');
            enableDisableButton.removeClass('icon-disable').addClass('icon-power');
            break;
        case 'unknown':
            circle.addClass('unknown');
            break;
        default:
          break;
    }

    $('section.actions-list[data-id=' + uid + ']').attr('data-mode', mode);
}


/**
 * Switch IQ Object warnings / errors status
 * @param {string} uid - the device uid
 * @param {boolean} hasWarnings
 * @param {boolean} hasErrors
 */
export function switchObjectStatus(uid, hasWarnings, hasErrors) {
    let deviceElem = getDeviceElement(uid);
    if(deviceElem.length === 0){
        return;
    }
    let circle = $('span.circle', deviceElem);
    if(circle.length === 0){
        return;
    }
    let newStatus = '';
    if(hasErrors) {
        newStatus = 'error';
    } else if (hasWarnings) {
        newStatus = 'warning';
    }

    circle.removeClass('warning error').addClass(newStatus);
}

/**
 * Change the displayed parameter for a given device.
 * @param {string} deviceUid - the device uid
 * @param {object} parameter - the parameter object
 * @param {string} rawValue - the new value
 */
export function switchDeviceParameter(deviceUid, parameter, rawValue){
    let value = paramToString(parameter, rawValue);
    getParameterElement(deviceUid, parameter.qid).html(
        safeString(value).val
    );
    getRawParameterElement(deviceUid, parameter.qid).attr('data-raw-value', rawValue);
}

/**
 * Change the displayed setting for a given IQ Object.
 * @param {string} uid - the IQ Object uid
 * @param {string} settingName - the setting name
 * @param {string} rawValue - the new value
 */
export function switchSetting(uid, settingName, rawValue){
    const iqObjectType = Helpers.iqObjectType(State.getIqObject(uid));
    const setting = Helpers.getSetting(iqObjectType, settingName);
    if(setting === false) {
        return rawValue;
    }
    let formattedValue = settingToString(iqObjectType, settingName, rawValue);
    $('.parameter[data-id="' + uid + '-' + settingName + '"]').not('form').html(
        formattedValue
    );
    $('section.parameter[data-uid="' + uid + '"][data-setting="' + settingName + '"]')
        .attr('data-raw-value', rawValue);
}

/**
 * Switch the CLFB input/output value
 * @param {string} uid - the CLFB uid
 * @param {string} name - the name (input or output)
 * @param {number} rawValue - the new Value
 */
export function switchClfbValue(uid, name, rawValue) {
    if(isNaN(rawValue)) {
        return '';
    }
    let formattedValue = Helpers.round(rawValue, 3).toString();
    $('.parameter[data-id="' + uid + '-' + name + '"]').html(
        formattedValue
    );
    $('section.parameter[data-uid="' + uid + '"][data-setting="' + name + '"]')
        .attr('data-raw-value', rawValue);
}

/**
 * Hide the device with the given uid
 * @param {string} uid - the device uid
 */
export function hideDevice(uid) {
    getDeviceElement(uid).addClass('hide');
}

/**
 * Show the device with the given uid
 * @param {string} uid - the device uid
 */
export function showDevice(uid) {
    getDeviceElement(uid).removeClass('hide');
}

export function switchObjectName(uid, name) {
    $('span.device-name', getDeviceElement(uid)).html(name);
    $('span.parameter[data-id=' + uid + '-name]').html(name);
}

export function switchDevicePosition(uid, position) {
    $('span.device-position', getDeviceElement(uid)).html(position);
}

/**
 * Switch device parameter to edit view
 * @param {String} actionId - the id for this edit action (UID-QID)
 * @param {String} currentValue - the current value of this parameter
 */
export function editParameter(actionId, currentValue) {
    const elem = $('form[data-id=' + actionId + ']');
    $('input', elem).each(() => {
        if($(this).attr('autofocus')){
            $(this).focus();
            return;
        }
    });
}

/**
 * Switch device parameter to regular view, disabling edit options
 * @param {String} actionId - the id for this edit action (UID-QID)
 */
export function stopEditingParameter(actionId) {
    $('form[data-id=' + actionId + ']').hide();
    Keyboard.hideKeyboard();
    window.scrollTo(0,0);
}

export function showError(id, msg){
    $('p.error[data-id=' + id + ']').html(msg).fadeIn();
}

export function hideError(id, msg){
    $('p.error[data-id=' + id + ']').hide();
}

/**
 * Hide the header icons
 */
export function hideIcons() {
    $('header div.icons').hide();
    $('header.main').addClass('loggedout');
}

/**
 * Hide the status list
 */
export function hideStatusList() {
    $('header div.status-list').hide();
}

/**
 * Show the status list
 */
export function showStatusList() {
    $('header div.status-list').show(400, resizeHomeLinkArea);
}

/**
 * Show the header icons
 */
export function showIcons() {
    $('header div.icons').show();
    $('header.main.loggedout').removeClass('loggedout');
    resizeHomeLinkArea();
}

/**
 * Show a loading icon in the given element
 */
export function enableLoadingIcon(elem) {
    $(elem).addClass('has-loading-icon');
}

export function disableLink(elem) {
    $(elem).addClass('no-link');
}

export function enableLink(elem) {
    $(elem).removeClass('no-link');
}

/**
 * Find the icon span in the given element and disable it's loading status
 * @param {Object} elem - the DOM element
 */
export function disableLoadingIcon(elem) {
    $(elem).removeClass('has-loading-icon');
}

/**
 * Removes all loading icons
 */
export function disableLoadingIcons() {
    $('.has-loading-icon').removeClass('has-loading-icon');
}

/**
 * Hide the hamburger menu if it's popped open
 */
export function hideMenu() {
    $('div.menu').hide();
}

export function updateUserName(loginData) {
    $('header a.username span').html(loginData.userName + ' (' + loginData.accessLevel + ')');
}

/**
 * Get a pretty message for an alarm or warning
 * @param {object} iqObject
 * @param {string} type - "warning", "alarm", "event" or "action"
 * @param {string|number} code
 */
export function eventMessage(iqObject, type, code) {
    let key = '';

    if(Helpers.isDevice(iqObject)) {
        if(!iqObject.hasOwnProperty('type')) {
            return '';
        }
        key = iqObject.type.toString();
    } else if(Helpers.isFieldbus(iqObject)) {
        key = 'fieldbus';
    } else if (Helpers.isClfb(iqObject)) {
        key = 'clfb';
    }

    if(
        window.CONSTANTS.EVENT_MAP.hasOwnProperty(key) &&
        window.CONSTANTS.EVENT_MAP[key].hasOwnProperty(type) &&
        window.CONSTANTS.EVENT_MAP[key][type].hasOwnProperty(code.toString())
    ) {
        return I8n.translate(window.CONSTANTS.EVENT_MAP[key][type][code.toString()]);
    }

    if(Helpers.isDevice(iqObject)) {
        key = 'all';
        if(
            window.CONSTANTS.EVENT_MAP.hasOwnProperty(key) &&
            window.CONSTANTS.EVENT_MAP[key].hasOwnProperty(type) &&
            window.CONSTANTS.EVENT_MAP[key][type].hasOwnProperty(code.toString())
        ) {
            return I8n.translate(window.CONSTANTS.EVENT_MAP[key][type][code.toString()]);
        }
    }

    return '';
}

/**
 * Removes all items in a table's tbody
 * @param {string} tableId
 */
export function clearTable(tableId) {
    $('table#' + tableId + ' tbody tr').remove();
}

/**
 * Hides/shows rows in a table based
 * @param {string} tableId
 * @param {string} attr - the attribute to filter on (i.e data-type)
 * @param {string} value - the value we want to keep, or ''
 *                 if you want to keep everything
 */
export function filterTable(tableId, attr, value){
    $('table#' + tableId + ' tbody tr').not('.event-help').show();
    if(value !== '') {
        $('table#' + tableId + ' tbody tr:not([' + attr + '=' + value + '])').hide();
    }
}

/**
 * Returns the icon filename for the given setting
 * @param {string} settingName
 */
function settingIcon(settingName){
    let name = 'SETTING_' + Helpers.camelToSnakeCase(settingName).toUpperCase();
    name = Helpers.replaceAll(name, ' ', '_');
    name = Helpers.replaceAll(name, '-', '_');

    if(window.CONSTANTS.ICONS.hasOwnProperty(name)){
        return 'images/icons/' + window.CONSTANTS.ICONS[name];
    }

    Debug.log('Icon not found for setting ' + name);
    return 'images/default-icon.svg';
}

export function getActionElementById(id) {
    return $('a.action[data-id=' + id + ']');
}

/**
 * There's an invisible link to the home page in the main header. It covers
 * most of the left side and is automatically resized so it doesn't overlap the
 * status icons.
 */
export function resizeHomeLinkArea() {
    // FIXME: I would rather get rid of this altogether and do the entire
    // header layout in proper css

    const elem = $('div.status-list a:first-child');
    let width = $(window).width() / 3;
    if(elem.length > 0) {
        let newWidth = elem.position().left;
        if(newWidth > 50) {
            width = newWidth;
        }
    }
    $('a.home-link-area').width(width);
}

/**
 * Make a button green and show "done" for a couple of seconds after
 * re-enabling the button
 * @param {object} elem - the DOM element
 */
export function showButtonDoneState(elem) {
    const oldText = $(elem).html();
    $(elem)
        .html(_('Done'))
        .removeClass('btn-blue')
        .addClass('btn-green');
    window.setTimeout(() => {
        $(elem)
            .html(oldText)
            .removeClass('btn-green')
            .addClass('btn-blue')
            .prop('disable', false);
    }, 3000);
}


/**
 * Make a button red and show an error message for a couple of seconds after
 * re-enabling the button
 * @param {object} elem - the DOM element
 * @param {boolean} disable - whether to also Disable the button
 */
export function showButtonErrorState(elem, msg='Error', disable=false) {
    const oldText = $(elem).html();
    $(elem)
        .html(_(msg))
        .removeClass('btn-blue')
        .addClass('btn-red')
        .addClass('btn-error');
    if(disable) {
        elem.addClass('no-link');
    }
    window.setTimeout(() => {
        $(elem)
            .html(oldText)
            .removeClass('btn-red')
            .removeClass('btn-error')
            .addClass('btn-blue')
            .prop('disable', false);
        if(disable) {
            elem.removeClass('no-link');
        }
    }, 3000);
}

export function loadingSpinnerHtml() {
    return '<span class="has-loading-icon"><span class="icon-spin-6"></span></span>';
}


/**
 * Screen overrides are situations in which you want to take away the current
 * GUI and show a different screen that is layered on top. I.E a screen showing
 * that the system is updating, starting, or rebooting. The overrides can occur
 * in any route and don't affect the url.
 */
function initScreenOverrides() {
    Signals.addListener('internal/show-override-screen', (msg) => {
        let message = '';
        if(msg.hasOwnProperty('data') && msg.data.hasOwnProperty('msg')) {
            message = msg.data.msg;
        }
        $('section.override-screen h1').html(message);
        $('section.override-screen').fadeIn();
    });

    Signals.addListener('internal/hide-override-screen', () => {
        $('section.override-screen').fadeOut();
    });

    Signals.addListener('internal/show-sub-override-screen', (msg) => {
        let message = '';
        if(msg.hasOwnProperty('data') && msg.data.hasOwnProperty('msg')) {
            message = msg.data.msg;
        }
        $('section.sub-override-screen h1').html(message);
        $('section.sub-override-screen').fadeIn();
    });

    Signals.addListener('internal/hide-sub-override-screen', () => {
        $('section.sub-override-screen').fadeOut();
    });
}

/**
 * Initialize the time system, listening to the managers time updates and
 * updating the shown time accordingly
 */
function initTime() {
    const updateTime = () => {
        $('time.system-time').html(State.getPrettyTime());
    };
    Signals.addListener('internal/minute-passed', updateTime);
}

/**
 * Return a setting's value as a properly formatted string
 * @param {string} iqObjectType - i.e "clfb" or "fieldbus"
 * @param {string} settingName
 * @returns {string} - the formatted string
 */
function settingToString(iqObjectType, settingName, settingVal) {
    const setting = Helpers.getSetting(iqObjectType, settingName);
    if(setting === false) {
        return settingVal;
    }

    if(setting.settingType === 'bool') {
        if(settingVal === true) {
            return _('Yes');
        } else {
            return _('No');
        }
    } else if(setting.settingType === 'enum') {
        for(let option of setting.options) {
            if(option.value === settingVal) {
                return option.name;
            }
        }
    } else if(setting.viewAsType === 'segments') {
        let valueArray = [];
        let minWidth = 16;
        let output = '';

        valueArray = Helpers.numberToBitArray(settingVal, minWidth);

        // Bitmasks are shown as little endian, but they're internally
        // processed as big endian, so we need to reverse them before showing
        //
        valueArray.reverse();

        for(let i = 0; i < valueArray.length; i++) {
           if(valueArray[i] === true) {
               output += '☑';
           } else {
               output += '☐';
           }
        }

        return output;
    } else if(setting.viewAsType === 'default' && setting.settingType === 'number') {
        let decimals = 3;
        if(setting.decimals) {
            decimals = setting.decimals;
        }
        settingVal = Helpers.round(settingVal, decimals);
        if(setting.unit) {
            settingVal += ' ' + setting.unit;
        }
    }

    return settingVal;
}

/**
 * Return a setting's "view as type". This is useful when rendering this
 * setting to a string
 * @param {string} iqObjectType - i.e "clfb" or "fieldbus"
 * @param {string} settingName
 * @returns {string} - the "view as type", which can be empty, bool, enum or
 * segments. If it's empty it means it indicates a default "raw" view"
 */
function settingViewAsType(iqObjectType, settingName) {
    const setting = Helpers.getSetting(iqObjectType, settingName);

    if(setting === false) {
        return '';
    } else if(setting.settingType === 'bool') {
        return 'bool';
    } else if(setting.settingType === 'enum') {
        return 'enum';
    } else if(setting.viewAsType === 'segments') {
        return 'segments';
    }

    return '';
}

/**
 * Toggle the fullscreen mode
 */
export function toggleFullScreen() {
    if(document.fullscreenElement) {
        document.exitFullscreen();
    } else {
        document.documentElement.requestFullscreen({navigationUI: "show"}).then(() => {

        }).catch(() => {

        });
    }
}

export function fullScreenChange() {
    if(document.fullscreenElement) {
        $('a.fullscreen span')
            .removeClass('icon-fullscreen')
            .addClass('icon-exit-fullscreen');
    } else {
        $('a.fullscreen span')
            .removeClass('icon-exit-fullscreen')
            .addClass('icon-fullscreen');
    }

}

export function showHelpSection() {
    $('section.help').slideDown();
}

export function toggleHelpSection() {
    let helpSection = $('section.help');
    if(helpSection.is(':visible')) {
        $('section.help').slideUp();
    } else {
        $('section.help').slideDown();
    }
}


export function closeHelpSection() {
    $('section.help').slideUp();
}

/**
 * @param {string} helpText - the markdown text
 * @returns {string} the html
 */
function renderHelpText(helpText) {
    return safeString(Help.render(helpText));
}

/**
 * @param {object} iqObject
 * @param {object} action
 * @returns boolean
 */
function showActionInGrid(iqObject, action) {
    if(Helpers.isDevice(iqObject)) {
        return Boolean(Helpers.optChain(
            window.CONSTANTS.ACTIONS.device,
            iqObject.type,
            action.name,
            'showInGrid'
        ));
    } else if(Helpers.isFieldbus(iqObject)) {
        return Boolean(Helpers.optChain(
            window.CONSTANTS.ACTIONS.fieldbus,
            action.name,
            'showInGrid'
        ));
    } else if(Helpers.isClfb(iqObject)) {
        return Boolean(Helpers.optChain(
            window.CONSTANTS.ACTIONS.clfb,
            action.name,
            'showInGrid'
        ));
    }
    return false;
}

/**
 * Returns whether a given setting can be shown in the general setting/action
 * grid on the main iq object page
 * @param {string} iqObjectType - i.e "clfb" or "fieldbus"
 * @param {string} settingName
 * @returns {boolean}
 */
function showSettingInGrid(iqObjectType, settingName) {
    const setting = Helpers.getSetting(iqObjectType, settingName);
    return setting.showInGrid === true;
}

/**
 * Retrieve the group number for a given parameter
 * @param {object} device
 * @param {object} parameter
 * @returns {string}
 */
function getParameterGroup(device, parameter) {
    const group = Helpers.optChain(window.CONSTANTS.PARAMETERS, device.type.toString(), parameter.qid.toString(), 'hmiGroup');
    if(!group) {
        return '';
    }
    return group.toString();
}

/**
 * Retrieve the group number for a given setting
 * @param {string} objectType - type (i.e "fieldbus" or "clfb")
 * @param {string} settingName
 * @returns {string}
 */
function getSettingGroup(objectType, settingName) {
    let setting = Helpers.getSetting(objectType, settingName);
    if(setting && setting.hmiGroup) {
        return setting.hmiGroup.toString();
    }
    return '10';
}

/**
 * Show the small mode button for device with given uid
 * @param {string} uid - the device uid
 */
export function showSmallModeButton(uid) {
    let device = State.getDevice(uid);
    if(!device) {
        return;
    }

    const elem = $('section.device[data-uid=' + uid + '] a.toggle-mode-small');
    const mode = device.status.mode;

    if(mode === 'running') {
        elem.html('<span class="icon-pause"></span>');
    } else if(mode === 'standby') {
        elem.html('<span class="icon-play"></span>');
    }

    if(mode === 'running' || mode === 'standby') {
        elem.show();
    } else {
        elem.hide();
    }

}

export function hideSmallModeButton(uid) {
    $('section.device[data-uid=' + uid + '] a.toggle-mode-small').hide();
}

/**
 * @param {object} msg
 * @returns {string}
 */
function updateTargetName(msg) {
    let target = '';

    if(msg.data.status === 'slc') {
        target = 'SLC';
    } else if(msg.data.status === 'device') {
        let device = State.getDevice(msg.data.target);
        if(device === false) {
            target = 'device';
        } else {
            target = device.name;
        }
    }
    return target;
}

function initUpdateListener() {
    let updateMessageBarId = -1;

    Signals.addListener('internal/close-update-message-bar', (msg) => {
        $('div#message-' + updateMessageBarId).fadeOut(400, () => {
            $(this).remove();
        });
        updateMessageBarId = -1;
    });

    Signals.addListener('internal/show-update-message-bar', (msg) => {
        updateMessageBarId = Messages.addPersistentMessage(
            _('Updating') + ': ' + updateTargetName(msg),
            'neutral',
            '/#/settings'
        );
    });

    Signals.addListener('internal/refresh-update-message-bar', (msg) => {
        if(updateMessageBarId === -1) {
            return;
        }

        $('div#message-' + updateMessageBarId + ' a.message').html(
            _('Updating') + ': ' + updateTargetName(msg)
        );
    });
}
