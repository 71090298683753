/**
 * Events module.
 * The Events module is the main event system for handling link clicks and
 * form submits.
 * @module events
 */

"use strict";

import {createNewEvent} from './helpers';
import * as Debug from './debug';
import * as Actions from './actions';
import * as Routing from '../routing';
import * as Gui from './gui';
import * as Helpers from './helpers';
import * as State from './state';
import * as Signals from './signals';
import * as Keyboard from './keyboard';

let initialized = false;

export function initEvents() {
    if(initialized) {
        return;
    }

    Debug.log('Initializing events');

    $('a.start-screensaver').click((event) => {
        event.preventDefault();
        event.stopPropagation();
        if ($('div.menu').has(event.target).length > 0){
            $('div.menu').hide();
        }
        Debug.recordEvent('start-screensaver');
        window.visit('/#/screensaver', event, {}, false, true);
    });

    $('a.fullscreen').click((event) => {
        event.preventDefault();
        event.stopPropagation();
        Gui.toggleFullScreen();
    });

    $(document).on('click', 'div.screensaver div.status-list a', ev => {
        Debug.recordEvent('hide-screensaver');
        Gui.hideScreenSaver();
    });

    $(document).on('click', 'button.action, a.action:not(.no-link)', triggerAction);
    $(document).on('change', 'select.action', triggerAction);
    $(document).on('click', 'button.back, a.back', backButtonClick);
    $(document).on('click', 'a.scroll-to-top', scrollToTop);
    $(document).on(
        'click',
        "a:not([rel='external']):not(.action, .toggle-keyboard, .no-link, .back, .stop-screensaver)",
        linkClick
    );
    $(document).on('submit', 'form', formSubmit);
    $(document).on('click', 'a.menu', menuClick);
    $(document).on('click', 'a.no-link', (el) => {
        el.preventDefault();
        el.stopPropagation();
    });

    // Keyboard specific events
    $(document).on('click', '.toggle-keyboard', Keyboard.toggleKeyboard);
    $(document).on(
        'focus',
        'input[type=text], input[type=number], input[type=password], textarea',
        Keyboard.focusElement
    );

    $(document).on('change', 'input.segment[type=checkbox]', toggleSegmentCheckbox);

    $(document).on('click', 'a.close-message', (event) => {
        if(!$(event.currentTarget).hasClass('message')) {
            event.preventDefault();
            event.stopPropagation();
        }
        $(event.currentTarget).parent().fadeOut(400, () => {
            $(event.currentTarget).parent().remove();
        });
    });

    $(window).on('popstate', function(event){
        Keyboard.hideKeyboard();
        Routing.visit(
            window.location.pathname + window.location.hash,
            createNewEvent('popstate'),
            false,
            false
        );
    });

    $(window).resize(resizeHandler);

    $(document).on(
        'mozfullscreenchange webkitfullscreenchange fullscreenchange',
        Gui.fullScreenChange
    );

    // Listen to global device status signals
    initGlobalSignals();
    initialized = true;
}

/**
 * Initialize the global signals for device status/mode changes.
 */
function initGlobalSignals() {
    Signals.addListener('device/status', (msg) => {
        State.updateDevice(msg.data);
        const uid = msg.data.uid;
        Gui.switchDeviceMode(
            uid,
            msg.data.status.mode
        );
        Gui.switchObjectName(uid, msg.data.name);
        Gui.switchObjectStatus(
            uid,
            msg.data.status.warnings,
            msg.data.status.errors
        );
        Gui.switchDevicePosition(uid, msg.data.position);
        Gui.refreshStateList();
    });

    Signals.addListener('fieldbus/status', (msg) => {
        State.updateFieldbus(msg.data);
        const uid = msg.data.uid;
        Gui.switchFieldbusMode(
            uid,
            msg.data.status.mode
        );
        Gui.switchObjectStatus(
            uid,
            msg.data.status.warnings,
            msg.data.status.errors
        );
        Gui.switchObjectName(uid, msg.data.name);
        Gui.refreshStateList();
    });

    Signals.addListener('clfb/status', (msg) => {
        State.updateClfb(msg.data);
        const uid = msg.data.uid;
        Gui.switchClfbMode(
            uid,
            msg.data.status.mode
        );
        Gui.switchObjectStatus(
            uid,
            msg.data.status.warnings,
            msg.data.status.errors
        );
        Gui.switchObjectName(uid, msg.data.name);
        Gui.refreshStateList();
    });
}

/**
 * This handler is called when the window is resized
 */
function resizeHandler() {
    Gui.resizeHomeLinkArea();

    if($(window).width() > 950 && $('div.menu').is(':visible')) {
        $('div.menu').hide();
    }

    Signals.emit({
        tag: 'screen/resize',
        data: {
            width: $(window).width(),
            height: $(window).height(),
        },
    });

}

/**
 * Pass the given event to the actions module for triggering
 * the connected action handlers.
 * @param {Event} ev - the DOM event
 */
function triggerAction(ev){
    const target = $(ev.currentTarget);
    if(target.hasClass('action')){
        ev.preventDefault();
        ev.stopPropagation();
        Actions.trigger(target);
    }
}

/**
 * General event handler for link clicks. This is called on all links ("a
 * tags" that don't have rel="external". It automatically prevents the
 * default handler from taking action, and stops propagation (so events
 * don't bubble op the DOM). After this, it calls the [[Routing.visit]]
 * function, so it can take appropriate action on this event.
 * @param {Event} event - the Event object
 */
function linkClick(event) {
    const target = $(event.currentTarget);

    var href = target.attr('href');

    // Clicked on an anchor? Don't do anything
    if(href.length > 0 && href[0] === '#'){
        return;
    }

    event.preventDefault();
    event.stopPropagation();

    // Clicked on a mobile menu item => hide the menu
    // @ts-ignore
    if ($('div.menu').has(target).length > 0){
        $('div.menu').hide();
    }

    Keyboard.hideKeyboard();

    Debug.recordEvent('link-click', {
        href: href,
    });

    Routing.visit(href, event);
}

/**
 * This function is triggered whenever the menu button is clicked. It toggles the menu.
 * @param {Event} event - the Event object
 */
function menuClick(event) {
    event.preventDefault();
    event.stopPropagation();
    $('div.menu').toggle();
}

/**
 * Back button event handler
 */
function backButtonClick(event = createNewEvent('')) {
    event.stopPropagation();
    event.preventDefault();
    Debug.recordEvent('back-button');
    history.back();
}

/**
 * General event handler for form submits. This is called on all forms that
 * trigger the submit event. It prevents the default handler from taking
 * action, but does not stop propagation. It passes the event through to
 * [[Routing.visit]] so it can take appropriate action on this event.
 * @param {Event} event - the Event object
 */
function formSubmit(event) {
    event.preventDefault();
    const target = $(event.currentTarget);
    if(Keyboard) {
        Keyboard.hideKeyboard();
    }

    if(target.hasClass('action')){
        event.preventDefault();
        event.stopPropagation();
        Actions.trigger(target);
    } else {
        let serializedData = target.serialize();
        const href = $(event.currentTarget).attr('action');
        Debug.recordEvent(
            'form-submit', {
                'data': target.serialize(),
                'href': href,
            });
        Routing.visit(href, event);
    }
}

function toggleSegmentCheckbox(event) {
    let bitString = '';
    $('input.segment', $(event.currentTarget).closest('div.segment-select')).each(
        /**
         * @type {function(number, HTMLInputElement)}
         */
        (i, elem) => {
            if(elem.checked) {
                bitString += '1';
            } else {
                bitString += '0';
            }
        }
    );

    $('input[data-id="' + $(event.currentTarget).attr('data-target-id') + '"]').val(
        // Segments are actually bitmasks, however we show and edit them as
        // little endian while they're being saved as big endian. This is why
        // we need to reverse them before saving
        parseInt(Helpers.reverseString(bitString), 2)
    );
}

function scrollToTop(event) {
    event.preventDefault();
    event.stopPropagation();
    window.scrollTo(0,0);
}

