/**
 * Debug module.
 * This module acts as an intermediate between the HMI proper and the HMI
 * debugger.
 */

"use strict";

let logHandler = (msg, record, type) => {};
let updateStateHandler = (state) => {};
let eventRecorder = (type, args) => {};
let playingBack = () => false;
let flagErrorHandler = (msg) => {};

/**
 * Show a log message and add to log history if debugging is enabled
 * @param {any} msg - the log message
 * @param {boolean} record - whether to record this message in the log history
 * @param {string} type - this log's type, used for filtering debug messages
 */
export function log(msg, record=true, type='general') {
    logHandler(msg, record, type);
}

export function flagError(msg='') {
    flagErrorHandler(msg);
}

export function updateState(state) {
    updateStateHandler(state);
}

export function setLogHandler(handler) {
    logHandler = handler;
}

export function setUpdateStateHandler(handler) {
    updateStateHandler = handler;
}

export function recordEvent(type, args) {
    eventRecorder(type, args);
}

export function setEventRecorder(handler) {
    eventRecorder = handler;
}

export function setPlayingBack(handler) {
    playingBack = handler;
}

export function isPlayingBack() {
    return playingBack();
}

export function setFlagError(func) {
   flagErrorHandler = func;
}
