"use strict";

/**
 * Chart Widgetmodule
 * @module linechartwidget
 */

import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as State from '../core/state';
import {Widget} from './widget';

export class LineChartWidget extends Widget {
    constructor(title, type='line', realtime=false) {
        super();
        this.chart = null;
        this.chartType = type;
        this.dataQueue = [];
        this.datasets = [];
        this.parentElem = null;
        this.realtime = realtime;
        this.title = title;
    }

    destructor() {
        if(this.chart !== null) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    /**
     * Add a new dataset
     * @param {string} label
     * @param {array} data
     * @param {string} color - optional hex color
     * @param {number} pointRadius - thickness of the points
     * @returns {number} - the index of this dataset
     */
    addDataSet(label, data=[], color='#0076ea', pointRadius=2) {
        let newLength = this.datasets.push({
            label: label,
            borderColor: color,
            data: data,
            borderWidth: 1,
            pointRadius: 2,
            spanGaps: !this.realtime,
        });
        this.dataQueue.push([]);
        return newLength - 1;
    }

    /**
     * Add a datapoint to a given dataset
     * @param {number} dataSetIndex
     * @param {number} x
     * @param {number} y
     */
    addPoint(dataSetIndex, x, y) {
        this.dataQueue[dataSetIndex].push([x, y]);
    }

    /**
     * Create basic chart configuration for chart.js
     * @returns {object}
     */
    getChartConfig() {
        let config = {
            type: 'line',
            data: {
                datasets: this.datasets,
            },
            options: {
                title: {
                    display: true,
                    text: this.title,
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            displayFormats: {
                                day: 'DD MMM YYYY',
                                hour: this.getTimeFormat('hour'),
                                minute: this.getTimeFormat('minute'),
                                second: this.getTimeFormat('second'),
                            }
                        }
                    },
                    y: {
                        beginAtZero: true
                    }
                },
                tooltips: {
                    enabled: true,
                },
                hover: {
                    enabled: true,
                },
                legend: {
                    display: false,
                },
                animation: {
                    duration: 0, // disable animations
                },
                elements: {
                    line: {
                        tension: 0 // disables bezier curves
                    }
                },
                responsiveAnimationDuration: 0,
                plugins: {
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true,
                            },
                            mode: 'x',
                        },
                        pan: {
                            enabled: true,
                            mode: 'xy',
                        },
                    },
                    decimation: {
                        enabled: false,
                        algorithm: 'lttb',
                        samples: 500,
                    },
                    tooltip: {
                        enabled: true
                    },
                },
            },
        };

        if(this.realtime) {
            let onRefresh = function(chart) {
                let i = 0;
                for(let dataset of this.datasets) {
                    while(this.dataQueue[i].length > 0){
                        const el = this.dataQueue[i].shift();
                        const timestamp = el[0];
                        dataset.data.push({
                            x: timestamp,
                            y: el[1]
                        });
                    }
                    i++;
                }
            };

            config.options.scales.x = {
                type: 'realtime',
                realtime: {
                    onRefresh: onRefresh.bind(this),
                    duration: 120000,
                    ttl: 200000,
                },
                time: {
                    displayFormats: {
                        hour: this.getTimeFormat('hour'),
                        day: 'DD MMM YYYY',
                        minute: this.getTimeFormat('minute'),
                        second: this.getTimeFormat('second'),
                        millisecond: 'HH:mm:ss',
                    },
                    stepSize: 10,
                }
            };

        }

        return config;
    }

    getTimeFormat(unit) {
        const use24 = State.get24HoursIsPreferred();
        if(unit === 'hour') {
            if(use24) {
                return 'HH';
            } else {
                return 'hh A';
            }
        } else if(unit === 'minute') {
            if(use24) {
                return 'HH:mm';
            } else {
                return 'hh:mm A';
            }
        } else {
            if(use24) {
                return 'HH:mm:ss';
            } else {
                return 'hh:mm:ss A';
            }
        }
    }

    /**
     * Resets the chart's zoom level to the default
     */
    resetZoom() {
        this.chart.resetZoom();
    }

    /**
     * Chart zoom in action handler
     */
    zoomIn() {
        this.chart.zoom(1.3);
    }

    /**
     * Chart zoom out action handler
     */
    zoomOut() {
        this.chart.zoom(0.7);
    }

    /**
     * @param {object} parentElem - the jQuery element of the parent
     */
    render(parentElem) {
        this.parentElem = parentElem;
        this.parentElem.append(
            Gui.renderToString(
                'widgets/chart.html',
                {id: this.id}
            )
        );

        let canvas = document.getElementById('chart-' + this.id);

        if(canvas instanceof HTMLCanvasElement) {
            let ctx = canvas.getContext('2d');
            this.chart = new Chart(ctx, this.getChartConfig());
        }
        this.resetSize();
    }

    resetSize() {
        const width = this.parentElem.width();

        let maxHeight = Math.round($(window).height() - this.parentElem.offset().top);
        this.parentElem.css('max-height', maxHeight + 'px');

        // Make sure this widget takes up all the available space in the parent
        // element. So we subtract all the siblings' heights
        let availableHeight = this.parentElem.height();
        $(this.chart.canvas.parentNode).siblings().each((index, elem) => {
            availableHeight -= $(elem).height();
        });

        this.chart.canvas.parentNode.style.height = availableHeight + 'px';
        this.chart.canvas.parentNode.style.width = width + 'px';
    }

    update() {
        this.chart.update();
    }
}
