"use strict";

let translations = {
    // ../src/templates/time.html,
    '24H or AM/PM': '24H lub AM/PM',

    // IQ4 Text for event WARNING_MASK_24V_OUTPUT_OVERLOAD,
    '24v output overload': '24v wyjście przeciążone',

    // ../src/templates/chart.html,
    '48h chart': 'wykres 48h',

    // ../src/templates/log.html,
    'Actions': 'Działania',

    // ../src/templates/parameter.html,src/templates/chart.html,src/templates/setting.html,
    'Actual value': 'Aktualna wartość',

    // ../src/templates/user.html,
    'Add new user': 'Dodaj nowego użytkownika',

    // ../src/templates/related-add.html,
    'Add related objects': 'Dodaj powiązane obiekty',

    // Action title for add-to-clfb,
    'Add to CLFB': 'Dodaj do CLFB',

    // IQ4 Text for parameter CLEANFLEX_AIR_PRESSURE,IQ4 Text for parameter BLOWFLEX_AIR_PRESSURE,
    'Air pressure': 'Ciśnienie powietrza',

    // IQ4 Text for parameter CLEANFLEX_TRIGGER_LEVEL,IQ4 Text for parameter BLOWFLEX_TRIGGER_LEVEL,
    'Air pressure trigger level high voltage': 'Wysoki poziom ciśnienia powietrza',

    // IQ4 Text for parameter GATEWAY_ALARM_SETPOINT,IQ4 Text for parameter GATEWAY_EX_ALARM_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_ALARM_SETPOINT,
    'Alarm level, measured value over maximum': 'Alarm, wartość zmierzona powyżej maksymalnej',

    // IQ4 Text for parameter GATEWAY_ALARM_MINIMUM,IQ4 Text for parameter GATEWAY_EX_ALARM_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_ALARM_MINIMUM,
    'Alarm level, measured value under minimum': 'Alarm, wartość zmierzona poniżej minimalnej',

    // ../src/templates/log.html,
    'Alarms': 'Alarmy',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_VALUE,
    'Ambient temperature': 'Temperatura otoczenia',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_AVAILABLE,
    'Ambient temperature sensor available': 'Aktywny czujnik temperatury',

    // ../src/../src/views/related.js,
    'An error occured while creating a new CLFB': 'Wystąpił błąd podczas tworzenia CLFB',

    // IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_MODE,IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_MODE,
    'Analog cleanbar enabled': 'Podłączona listwa analogowa',

    // IQ4 Text for parameter CM_TINY_ARC_DETECT_COUNTER,IQ4 Text for parameter IQ_COM_ARC_DETECT_COUNTER,IQ4 Text for parameter MPM_ARC_DETECT_COUNTER,
    'Arcs per minute counter': 'Licznik łuków na min',

    // ../src/templates/user-delete.html,
    'Are you sure you want to delete the user': 'Czy na pewno chcesz usunąć tego użytkownika',

    // IQ4 Text for parameter GATEWAY_AVAILABLE_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_AVAILABLE_SEGMENTS,
    'Available Sensor segments': 'Dostępne segmenty sensora',

    // ../src/templates/settings.html,src/templates/settings-updates.html,
    'Available updates': 'Dostępne aktualizacje',

    // ../src/templates/settings-updates.html,
    'Available version': 'Dostępna wersja',

    // ../src/templates/device.html,
    'Back to top': 'Powrót do Góry',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Before exporting the Fieldbus configuration you first have to disable it.': 'Przed wyeksportowaniem konfiguracji Fieldbus należy ją najpierw wyłączyć.',

    // IQ4 Text for setting CLFB_NAME,
    'CLFB name': 'Nazwa CLFB',

    // Action title for calibrate,
    'Calibrate bar': 'Kalibracja listwy',

    // ../src/../src/views/related.js,
    'Can not delete CLFB output': 'Nie można usunąć wyjścia CLFB',

    // ../src/templates/parameter.html,src/templates/setting.html,
    'Cancel': 'Anuluj',

    // IQ4 Text for parameter CMME_CHARGE_SINGLE_SHOT_TIME,IQ4 Text for parameter IQ_COM_CHARGE_SINGLE_SHOT_TIME,
    'Charge pulse single shot': 'Pojedynczy impuls ładowania',

    // ../src/templates/settings.html,src/templates/settings-install-updates-error.html,
    'Check available updates': 'Sprawdź dostępne aktualizacje',

    // IQ4 Text for parameter PERFORMAX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter EASION_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter THUNDERION2_CLEAN_BAR_WARNING_LEVEL,
    'Clean bar warning level': 'Ostrzeżenie poziom czystości listwy',

    // Action title for clear,
    'Clear action log': 'Wyczyść dziennik akcji',

    // ../src/templates/clfb.html,src/templates/log-table.html,src/templates/action.html,src/templates/parameter.html,src/templates/fieldbus.html,src/templates/setting.html,src/templates/device.html,
    'Close': 'Zamknij',

    // ../src/templates/log.html,
    'Code': 'Kod',

    // ../src/templates/connecting.html,
    'Connecting to websocket, please wait.': 'Łączenie z WebSocket, proszę czekać.',

    // ../src/templates/log-table.html,
    'Could not retrieve log': 'Nie można pobrać dziennika',

    // ../src/../src/views/setting.js,
    'Could not save the new settings': 'Nie można zapisać ustawień',

    // ../src/../src/views/parameter.js,
    'Could not save the new value': 'Nie można zapisać nowej wartości',

    // Action title for create-related,
    'Create related object': 'Utwórz powiązany obiekt',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_SETPOINT,
    'Current Control current setpoint': 'Sterowanie natężeniem wartość zadana',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_LIMIT_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_LIMIT_60KV_MNGR4,
    'Current Control output voltage limit': 'Sterowanie natężeniem limit napięcia wyjściowego',

    // ../src/templates/time.html,
    'Current system time in UTC': 'Aktualny czas systemu w UTC',

    // ../src/templates/time.html,
    'Current time zone': 'Strefa Czasowa',

    // ../src/templates/settings.html,src/templates/settings-updates.html,
    'Current version': 'Aktualna wersja',

    // IQ4 Text for parameter CMME_CYCLE_OK_HIGH_DELAY_THRESHOLD,
    'Cycle OK high delay': 'Cykl OK, duże opóźnienie',

    // IQ4 Text for parameter CMME_CYCLE_OK_LOW_LEVEL_THRESHOLD_MNGR4,
    'Cycle OK low level': 'Cykl OK, niski poziom',

    // ../src/templates/time.html,src/templates/log.html,
    'Date': 'Data',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Default value': 'Domyślna wartość',

    // ../src/templates/debug-test.html,src/templates/user.html,
    'Delete': 'Usuń',

    // Action title for delete-related,
    'Delete related object': 'Usuń powiązany obiekt',

    // ../src/templates/user-delete.html,
    'Delete user': 'Usuń użytkownika',

    // IQ4 Text for setting CLFB_DERIVATIVEGAIN,
    'Derivative Gain': 'Zysk pochodny',

    // ../src/templates/debug-report.html,
    'Description': 'Opis',

    // IQ4 Text for parameter CM_TINY_DEVICE_NAME,IQ4 Text for parameter CMME_DEVICE_NAME,IQ4 Text for parameter IQ_COM_DEVICE_NAME,IQ4 Text for parameter GATEWAY_DEVICE_NAME,IQ4 Text for parameter GATEWAY_EX_DEVICE_NAME,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_NAME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_DEVICE_NAME,IQ4 Text for parameter EASION_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_EX_DEVICE_NAME,IQ4 Text for parameter THUNDERION2_DEVICE_NAME,IQ4 Text for parameter VICINION_DEVICE_NAME,IQ4 Text for parameter CLEANFLEX_DEVICE_NAME,IQ4 Text for parameter BLOWFLEX_DEVICE_NAME,IQ4 Text for parameter MPM_DEVICE_NAME,
    'Device name': 'Nazwa urządzenia',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE,IQ4 Text for parameter CMME_DEVICE_TYPE,IQ4 Text for parameter IQ_COM_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_EX_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE,IQ4 Text for parameter EASION_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE,IQ4 Text for parameter VICINION_DEVICE_TYPE,IQ4 Text for parameter CLEANFLEX_DEVICE_TYPE,IQ4 Text for parameter BLOWFLEX_DEVICE_TYPE,IQ4 Text for parameter MPM_DEVICE_TYPE,
    'Device type': 'Typ urządzenia',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE_VERSION,IQ4 Text for parameter CMME_DEVICE_TYPE_VERSION,IQ4 Text for parameter IQ_COM_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE_VERSION,IQ4 Text for parameter EASION_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE_VERSION,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE_VERSION,
    'Device type version': 'Wersja typu urządzenia',

    // Action title for disable,
    'Disable': 'Wyłącz',

    // Action title for remove,
    'Disconnect': 'Rozłącz',

    // ../src/templates/log.html,
    'Dismiss log': 'Odrzuć log',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_AVAILABLE,
    'Distance sensor available': 'Dostępny czujnik odległości',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_MALFUNCTION,
    'Distance sensor malfunction': 'Usterka czujnika odległości',

    // IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE_OVERRIDE,
    'Distance sensor override': 'Przeciążony czujnik odległości',

    // ../src/../src/core/gui.js,
    'Done': 'Zrobione',

    // ../src/templates/user.html,
    'Edit': 'Edytuj',

    // ../src/templates/macros.html,
    'Edit parameter': 'Edytuj parametr',

    // Action title for edit-related,
    'Edit related objects': 'Edytuj wybrane obiekty',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Edit setting': 'Edytuj ustawienie',

    // ../src/templates/user-edit.html,
    'Edit user': 'Edytuj użytkownika',

    // Action title for enable,
    'Enable': 'Włącz',

    // IQ4 Text for parameter GATEWAY_ENABLED_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_ENABLED_SEGMENTS,
    'Enabled Sensor segments': 'Włączone segmenty sensora',

    // ../src/../src/views/time.js,
    'Error changing the system time': 'Błąd zmiany czasu systemowego',

    // ../src/../src/views/user.js,
    'Error deleting user': 'Błąd usuwania użytkownika',

    // ../src/templates/settings-install-updates-error.html,
    'Error installing updates': 'Błąd instalacji aktualizacji',

    // ../src/../src/views/chart.js,
    'Error retrieving history': 'Błąd pobierania historii',

    // ../src/../src/views/chart.js,
    'Error retrieving recent history': 'Błąd pobierania najnowszej historii',

    // ../src/../src/views/user.js,../src/../src/views/user.js,
    'Error: failed to create user': 'Błąd: nie udało się utworzyć użytkownika',

    // ../src/../src/views/user.js,
    'Error: failed to delete user': 'Błąd: nie udało się usunąć użytkownika',

    // ../src/../src/views/user.js,
    'Error: failed to edit user': 'Błąd: nie można edytować użytkownika',

    // ../src/../src/views/user.js,
    'Error: failed to update user': 'Błąd: nie udało się zaktualizować użytkownika',

    // ../src/templates/log.html,
    'Events': 'Wydarzenia',

    // Action title for export,
    'Export configuration': 'Eksportuj konfigurację',

    // ../src/templates/settings.html,
    'Factory name': 'Nazwa fabryczna',

    // ../src/../src/views/log.js,
    'Failed to clear the log': 'Nie udało się wyczyścić dziennika',

    // ../src/templates/failed-to-connect.html,
    'Failed to connect to the websocket, please check your settings.': 'Nie udało się połączyć z websocket, sprawdź ustawienia.',

    // ../src/../src/views/action.js,
    'Failed to perform the action ': 'Nie udało się wykonać akcji',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Failed to reset the password. Please check the username and recovery key.': 'Nie udało się zresetować hasła. Proszę sprawdzić nazwę użytkownika i klucz odzyskiwania.',

    // ../src/../src/views/related.js,
    'Failed to retrieve CLFB': 'Nie udało się pobrać CLFB',

    // ../src/../src/views/related.js,
    'Failed to retrieve device': 'Nie udało się odzyskać urządzenia',

    // ../src/../src/views/related.js,
    'Failed to retrieve fieldbus': 'Nie udało się odzyskać magistrali fieldbus',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to retrieve the CLFB': 'Pobieranie CLFB nie powiodło się',

    // ../src/../src/views/related.js,
    'Failed to retrieve the fieldbus': 'Nie udało się odzyskać magistrali fieldbus',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to update the CLFB settings': 'Nie udało się zaktualizować ustawień CLFB',

    // ../src/../src/views/related.js,
    'Failed to update the Fieldbus Map': 'Nie udało się zaktualizować mapy Fieldbus',

    // IQ4 Text for setting FIELDBUS_NAME,
    'Fieldbus name': 'Nazwa Fieldbus',

    // ../src/templates/log.html,
    'Filter': 'Filtr',

    // IQ4 Text for parameter CM_TINY_FIRMWARE_VERSION,IQ4 Text for parameter CMME_FIRMWARE_VERSION,IQ4 Text for parameter IQ_COM_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter SEGMENT_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_FIRMWARE_VERSION,IQ4 Text for parameter EASION_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_EX_FIRMWARE_VERSION,IQ4 Text for parameter THUNDERION2_FIRMWARE_VERSION,IQ4 Text for parameter VICINION_FIRMWARE_VERSION,IQ4 Text for parameter CLEANFLEX_FIRMWARE_VERSION,IQ4 Text for parameter BLOWFLEX_FIRMWARE_VERSION,IQ4 Text for parameter MPM_FIRMWARE_VERSION,
    'Firmware version': 'Wersja oprogramowania',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG1,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG1,
    'Firmware version seg1': 'Wersja oprogramowania seg1',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG10,
    'Firmware version seg10': 'Wersja oprogramowania seg10',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG11,
    'Firmware version seg11': 'Wersja oprogramowania seg11',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG12,
    'Firmware version seg12': 'Wersja oprogramowania seg12',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG13,
    'Firmware version seg13': 'Wersja oprogramowania seg13',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG14,
    'Firmware version seg14': 'Wersja oprogramowania seg14',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG15,
    'Firmware version seg15': 'Wersja oprogramowania seg15',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG16,
    'Firmware version seg16': 'Wersja oprogramowania seg16',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG2,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG2,
    'Firmware version seg2': 'Wersja oprogramowania seg2',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG3,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG3,
    'Firmware version seg3': 'Wersja oprogramowania seg3',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG4,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG4,
    'Firmware version seg4': 'Wersja oprogramowania seg4',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG5,
    'Firmware version seg5': 'Wersja oprogramowania seg5',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG6,
    'Firmware version seg6': 'Wersja oprogramowania seg6',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG7,
    'Firmware version seg7': 'Wersja oprogramowania seg7',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG8,
    'Firmware version seg8': 'Wersja oprogramowania seg8',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG9,
    'Firmware version seg9': 'Wersja oprogramowania seg9',

    // ../src/templates/settings.html,src/templates/settings-updates.html,
    'Hide release notes': 'Ukryj informacje o wersji',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,
    'Hide table': 'Ukryj tabelę',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter MPM_OUTPUT_CURRENT_FEEDBACK,
    'High voltage output current': 'Natężenie prądu na wyjściu',

    // IQ4 Text for parameter CMME_OUTPUT_CURRENT_LIMIT_MNGR4,
    'High voltage output current limit': 'Natężenie prądu na wyjściu limit',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_CURRENT_PEAK_MNGR4,
    'High voltage output current peak per cycle': 'Wysokie napięcie szczyt prądu wyjściowego na cykl',

    // IQ4 Text for parameter THUNDERION2_OUTPUT_FREQUENCY_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_FREQUENCY_SETPOINT,
    'High voltage output frequency': 'Częstotliwość wyjściowa wysokiego napięcia',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_FEEDBACK,
    'High voltage output voltage': 'Wysokie napięcie wyjściowe',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_ALARM,
    'High voltage overload alarm level': 'Poziom alarmu przeciążenia wysokiego napięcia',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_WARNING,
    'High voltage overload warning level': 'Poziom ostrzeżenia przeciążenia wysokiego napięcia',

    // ../src/templates/settings-updates.html,src/templates/user-edit.html,
    'Id': 'Identyfikator',

    // Action title for identify,
    'Identify': 'Identyfikuj',

    // ../src/templates/log.html,
    'Info': 'Informacja',

    // IQ4 Text for setting CLFB_INPUTSEGMENTS,
    'Input Segments Selection': 'Wybór segmentów wejściowych',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Input value': 'Wprowadź wartości',

    // ../src/templates/settings-updates.html,
    'Install all updates': 'Instaluj wszystkie aktualizacje',

    // IQ4 Text for setting CLFB_INTEGRALDEADBAND,
    'Integral Deadband': 'Zintegrowane puste pasmo',

    // IQ4 Text for setting CLFB_INTEGRALGAIN,
    'Integral Gain': 'Wzmocnienie integralne',

    // IQ4 Text for setting CLFB_INTEGRALMAX,
    'Integral Maximum': 'Maksimum Integralne',

    // IQ4 Text for setting CLFB_INTEGRALMIN,
    'Integral Minimum': 'Minimum integralne',

    // IQ4 Text for parameter CM_TINY_INTERNAL_TEMP,IQ4 Text for parameter CMME_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter QID_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE,IQ4 Text for parameter THUNDERION2_INTERNAL_TEMPERATURE,IQ4 Text for parameter VICINION_INTERNAL_TEMPERATURE,IQ4 Text for parameter EASION_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter BLOWFLEX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_INTERNAL_TEMP,
    'Internal temperature': 'Temperatura wewnętrzna',

    // ../src/templates/macros.html,
    'Internal use': 'Zastosowanie wewnętrzne',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Invalid login, please try again': 'Nieprawidłowe logowanie, spróbuj ponownie',

    // ../src/../src/views/parameter.js,
    'Invalid parameter': 'Nieprawidłowy parametr',

    // ../src/../src/views/related.js,
    'Invalid related object target': 'Nieprawidłowy obiekt powiązany',

    // ../src/../src/views/setting.js,
    'Invalid setting': 'Nieprawidłowe ustawienia',

    // IQ4 Text for parameter PERFORMAX_BALANCE_OFFSET,IQ4 Text for parameter EASION_BALANCE_OFFSET,IQ4 Text for parameter PERFORMAX_EX_BALANCE_OFFSET,IQ4 Text for parameter THUNDERION2_HV_BALANCE_OFFSET,
    'Ionisation balance offset': 'Przesunięcie balansu jonizacji',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEG,
    'Ionisation current negative emitters': 'Napięcie jonizacji emitery negatywne',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POS,
    'Ionisation current positive emitters': 'Napięcie jonizacji emitery pozytywne',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_EFFICIENCY,IQ4 Text for parameter EASION_IONIZATION_EFFICIENCY,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_EFFICIENCY,IQ4 Text for parameter THUNDERION2_BAR_EFFICIENCY,
    'Ionisation efficiency': 'Efektywność jonizacji',

    // ../src/templates/login.html,
    'Language': 'Język',

    // IQ4 Text for parameter CM_TINY_LAST_ALARM,IQ4 Text for parameter CMME_LAST_ALARM,IQ4 Text for parameter IQ_COM_LAST_ALARM,IQ4 Text for parameter GATEWAY_LAST_ALARM,IQ4 Text for parameter GATEWAY_EX_LAST_ALARM,IQ4 Text for parameter PERFORMAX_LAST_ALARM,IQ4 Text for parameter EASION_LAST_ALARM,IQ4 Text for parameter PERFORMAX_EX_LAST_ALARM,IQ4 Text for parameter THUNDERION2_LAST_ALARM,IQ4 Text for parameter VICINION_LAST_ALARM,IQ4 Text for parameter CLEANFLEX_LAST_ALARM,IQ4 Text for parameter BLOWFLEX_LAST_ALARM,IQ4 Text for parameter MPM_LAST_ALARM,
    'Last alarm date-time': 'Data ostatniego alarmu',

    // IQ4 Text for parameter SEGMENT_4_QID_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_LAST_CALIBRATION_DATE,IQ4 Text for parameter EASION_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_EX_LAST_CALIBRATION_DATE,
    'Last calibration date': 'Data ostatniej kalibracji',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG1,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG1,
    'Last calibration date seg1': 'Ostatnia data kalibracji seg1',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG10,
    'Last calibration date seg10': 'Ostatnia data kalibracji seg10',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG11,
    'Last calibration date seg11': 'Ostatnia data kalibracji seg11',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG12,
    'Last calibration date seg12': 'Ostatnia data kalibracji seg12',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG13,
    'Last calibration date seg13': 'Ostatnia data kalibracji seg13',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG14,
    'Last calibration date seg14': 'Ostatnia data kalibracji seg14',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG15,
    'Last calibration date seg15': 'Ostatnia data kalibracji seg15',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG16,
    'Last calibration date seg16': 'Ostatnia data kalibracji seg16',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG2,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG2,
    'Last calibration date seg2': 'Ostatnia data kalibracji seg2',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG3,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG3,
    'Last calibration date seg3': 'Ostatnia data kalibracji seg3',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG4,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG4,
    'Last calibration date seg4': 'Ostatnia data kalibracji seg4',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG5,
    'Last calibration date seg5': 'Ostatnia data kalibracji seg5',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG6,
    'Last calibration date seg6': 'Ostatnia data kalibracji seg6',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG7,
    'Last calibration date seg7': 'Ostatnia data kalibracji seg7',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG8,
    'Last calibration date seg8': 'Ostatnia data kalibracji seg8',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG9,
    'Last calibration date seg9': 'Ostatnia data kalibracji seg9',

    // IQ4 Text for parameter PERFORMAX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter EASION_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter PERFORMAX_EX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter THUNDERION2_LAST_CLEAN_BAR_WARNING,
    'Last clean bar warning date-time': 'Data ostatniego ostrzeżenia czyszczenia listwy',

    // IQ4 Text for parameter CM_TINY_LAST_WARNING,IQ4 Text for parameter CMME_LAST_WARNING,IQ4 Text for parameter IQ_COM_LAST_WARNING,IQ4 Text for parameter GATEWAY_LAST_WARNING,IQ4 Text for parameter GATEWAY_EX_LAST_WARNING,IQ4 Text for parameter PERFORMAX_LAST_WARNING,IQ4 Text for parameter EASION_LAST_WARNING,IQ4 Text for parameter THUNDERION2_LAST_WARNING,IQ4 Text for parameter VICINION_LAST_WARNING,IQ4 Text for parameter CLEANFLEX_LAST_WARNING,IQ4 Text for parameter BLOWFLEX_LAST_WARNING,IQ4 Text for parameter MPM_LAST_WARNING,
    'Last warning date-time': 'Data ostatniego ostrzeżenia',

    // ../src/templates/user-new.html,src/templates/user-edit.html,
    'Level': 'Poziom',

    // ../src/templates/debug.html,src/templates/debug-test.html,src/templates/debug-test.html,
    'Log': 'Dziennik',

    // ../src/templates/log.html,
    'Log for': 'Dziennik dla',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Loop selection': 'Wybór pętli',

    // IQ4 Text for parameter CM_TINY_MACHINE_POSITION,IQ4 Text for parameter CMME_MACHINE_POSITION,IQ4 Text for parameter IQ_COM_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_EX_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_MACHINE_POSITION,IQ4 Text for parameter EASION_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_EX_MACHINE_POSITION,IQ4 Text for parameter THUNDERION2_MACHINE_POSITION,IQ4 Text for parameter VICINION_MACHINE_POSITION,IQ4 Text for parameter CLEANFLEX_MACHINE_POSITION,IQ4 Text for parameter BLOWFLEX_MACHINE_POSITION,IQ4 Text for parameter MPM_MACHINE_POSITION,
    'Machine position (location)': 'Położenie na maszynie (lokalizacja)',

    // IQ4 Text for event CLFB_INPUT_WATCHDOG_ELAPSED,
    'Make sure the CLFB is being updated. Sensor is connected and running.': 'Upewnij się, że CLFB jest aktualizowana. Czujnik jest podłączony i uruchomiony.',

    // IQ4 Text for event CLFB_INPUT_DEVICE_NOT_READY,
    'Make sure the input device is available and in RUN mode': 'Upewnij się, że urządzenie wejściowe jest dostępne i w trybie pracy',

    // IQ4 Text for event CLFB_OUTPUT_DEVICE_NOT_READY,
    'Make sure the output device is available and in run mode': 'Upewnij się, że urządzenie wyjściowe jest dostępne i w trybie pracy',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MAX_TIME,
    'Max. Charge pulse duration': 'Maksymalny czas trwania impulsu',

    // IQ4 Text for parameter CM_TINY_MAX_INTERNAL_TEMP,IQ4 Text for parameter CMME_MAX_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter EASION_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter VICINION_MAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_MAX_INTERNAL_TEMP,
    'Max. internal temperature': 'Maks. temperatura wewnętrzna',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_MAX_OLD,
    'Max. primary current': 'Maks. prąd pierwotny',

    // IQ4 Text for parameter CM_TINY_MAX_QUANTITY_ARCS,IQ4 Text for parameter IQ_COM_MAX_QUANTITY_ARCS,
    'Max. quantity arcs per minute': 'Max. ilość łuków na minutę',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Maximum value': 'Wartość maksymalna',

    // ../src/templates/log.html,
    'Message': 'Wiadomość',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MIN_TIME,
    'Min. Charge pulse duration': 'Min. czas trwania impulsu ładowania',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Minimum value': 'Wartość minimalna',

    // IQ4 Text for parameter SENSOR_MOUNTING_DISTANCE,IQ4 Text for parameter SENSOR_EX_MOUNTING_DISTANCE,IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_MOUNTING_DISTANCE,IQ4 Text for parameter EASION_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_EX_MOUNTING_DISTANCE,IQ4 Text for parameter THUNDERION2_MOUNTING_DISTANCE,IQ4 Text for parameter VICINION_MOUNTING_DISTANCE,
    'Mounting distance': 'Odległość montażu',

    // ../src/templates/user-new.html,src/templates/settings.html,src/templates/settings-updates.html,src/templates/user-edit.html,
    'Name': 'Nazwa',

    // ../src/templates/related-add.html,
    'New Closed Loop Feedback': 'Nowa Closed Loop Feedback',

    // ../src/../src/views/log.js,
    'New log entries have been received. Click on the "refresh log" button to update the list.': 'Otrzymano nowe wpisy do dziennika. Kliknij przycisk "odśwież dziennik", aby zaktualizować listę.',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'No': 'Nie',

    // ../src/../src/core/gui.js,
    'Off': 'Wyłączony',

    // ../src/../src/core/gui.js,
    'On': 'Włączony',

    // IQ4 Text for parameter CM_TINY_OPERATION_MODE,IQ4 Text for parameter CMME_OPERATION_MODE,IQ4 Text for parameter IQ_COM_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_BAR_OPERATION_MODE,IQ4 Text for parameter EASION_BAR_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_EX_BAR_OPERATION_MODE,IQ4 Text for parameter THUNDERION2_OPERATION_MODE,IQ4 Text for parameter VICINION_BAR_OPERATION_MODE,
    'Operation mode': 'Tryb pracy',

    // IQ4 Text for parameter CMME_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_ALARM_CURRENT_PEAK_MNGR4,
    'Output current alarm limit': 'Alarm limitu natężenia wyjściowego',

    // IQ4 Text for parameter CMME_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_WARNING_CURRENT_PEAK_MNGR4,
    'Output current peak alarm limit': 'Alarm limitu natężenia wyjściowego',

    // IQ4 Text for parameter EASION_OUTPUT_FREQUENCY_SETPOINT,
    'Output frequency setpoint': 'Nastawa częstotliwości wyjściowej',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Output value': 'Wartość wyjściowa',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_MAX,
    'Output voltage max setpoint': 'Maksymalne nastawienie napięcia wyjściowego',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_VSENSE_NEG,
    'Output voltage negative emitters': 'Napięcie wyjściowe na negatywnych emiterach',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE_SETPOINT,
    'Output voltage negative setpoint': 'Napięcie wyjściowe nastawy ujemne',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_PEAK_MNGR4,
    'Output voltage peak per cycle': 'Wartość szczytowa napięcia wyjściowego na cykl',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_VSENSE_POS,
    'Output voltage positive emitters': 'Napięcie wyjściowe na pozytywnych emiterach',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE_SETPOINT,
    'Output voltage positive setpoint': 'Napięcie wyjściowe nastawy pozytywne',

    // ../src/templates/404.html,
    'Page could not be found': 'Nie można odnaleźć strony',

    // ../src/templates/macros.html,
    'Parameter locked': 'Parametr zablokowany',

    // ../src/templates/user-new.html,src/templates/user-edit.html,src/templates/login.html,
    'Password': 'Hasło',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Password reset': 'Zresetuj hasło',

    // ../src/../src/views/parameter.js,
    'Please input a valid number': 'Proszę wpisać poprawną cyfrę',

    // ../src/templates/parameter.html,
    'Plot 48h chart': 'Rysunek 48h wykresu',

    // ../src/templates/clfb-io.html,src/templates/parameter.html,
    'Plot realtime chart': 'Rysuj wykres w czasie rzeczywistym',

    // ../src/templates/settings.html,src/templates/settings-updates.html,
    'Position': 'Położenie',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CMME_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter THUNDERION2_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CLEANFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter BLOWFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE,
    'Power supply voltage': 'Napięcie zasilania',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter CMME_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE_WRN_LEVEL,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE_WARNING,
    'Power supply warning level': 'Poziom ostrzegania o zasilaniu',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter THUNDERION2_PRIMARY_CURRENT,IQ4 Text for parameter CLEANFLEX_PRIMARY_CURRENT,IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT,
    'Primary current': 'Prąd pierwotny',

    // IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT_CALIBRATION_OFFSET,
    'Primary current calibration offset': 'Przesunięcie kalibracji prądu pierwotnego',

    // IQ4 Text for setting CLFB_PROPORTIONALGAIN,
    'Proportional Gain': 'Proporcjonalny wzrost',

    // ../src/templates/chart.html,
    'Realtime chart': 'Wykres w czasie rzeczywistym',

    // Action title for reboot,
    'Reboot': 'Restart',

    // ../src/templates/password-reset.html,
    'Recovery key': 'Klucz odzyskiwania',

    // ../src/templates/log.html,
    'Refresh log': 'Odśwież dziennik',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_VALUE,
    'Relative Humidity': 'Wilgotność względna',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_AVAILABLE,
    'Relative Humidity sensor available': 'Dostępny czujnik wilgotności względnej',

    // ../src/templates/settings.html,src/templates/settings-updates.html,
    'Release notes': 'Lista zmian',

    // ../src/templates/customize-view.html,
    'Reorder': 'Zmiana kolejności',

    // ../src/templates/debug.html,src/templates/debug-report.html,
    'Report': 'Raport',

    // ../src/templates/password-reset.html,
    'Reset password': 'Resetuj hasło',

    // ../src/templates/clfb-chart.html,src/templates/chart.html,
    'Reset zoom': 'Zresetuj powiększenie',

    // ../src/../src/core/state.js,
    'Restarting the system, please wait': 'Uruchamianie systemu ponownie, proszę czekać',

    // ../src/../src/core/state.js,
    'Restarting, please wait': 'Uruchamianie ponownie, proszę czekać',

    // Action title for restore-factory,
    'Restore factory settings': 'Przywracanie ustawień fabrycznych',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Result': 'Wynik',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Run': 'Uruchom',

    // ../src/templates/debug-test.html,
    'Run test selection': 'Wybór testu działania',

    // ../src/templates/user-new.html,src/templates/parameter.html,src/templates/time.html,src/templates/time.html,src/templates/setting.html,src/templates/user-edit.html,
    'Save': 'Zapisz',

    // IQ4 Text for setting CLFB_SEGMENTSELECTION,
    'Segment Selection Mode': 'Wybór segmentu',

    // ../src/templates/related-select-slot.html,
    'Select fieldbus slot': 'Wybór magistrali Fieldbus',

    // ../src/templates/debug-report.html,
    'Send': 'Wyślij',

    // ../src/templates/debug-test.html,
    'Sequences': 'Kolejność',

    // IQ4 Text for event CLFB_SETPOINT_NOT_READY,
    'Set all requested setpoint settings and relations.': 'Ustaw wszystkie żądane ustawienia i relacje.',

    // Action title for run,
    'Set device in RUN': 'Ustaw urządzenie na PRACA',

    // ../src/templates/time.html,
    'Set local settings': 'Ustaw ustawienia lokalne',

    // ../src/templates/time.html,
    'Set new system time in UTC': 'Ustaw nowy czas systemowy w UTC',

    // IQ4 Text for parameter BLOWFLEX_SETPOINT_CURRENT,
    'Setpoint current': 'Prąd zadany',

    // IQ4 Text for parameter CM_TINY_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter IQ_COM_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter CMME_SETPOINT_VOLTAGE_CLFB,
    'Setpoint voltage balance for CLFB': 'Bilans napięcia zadanego dla CLFB',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Setpoint voltage percent': 'Napięcie zadane procent',

    // ../src/templates/macros.html,
    'Setting locked': 'Ustawienia zablokowane',

    // ../src/templates/settings.html,src/templates/settings-updates.html,
    'Settings': 'Ustawienia',

    // ../src/templates/log.html,
    'Show all ': 'Pokaż wszystkie',

    // ../src/templates/settings-updates.html,
    'Show current versions': 'Pokaż bieżące wersje',

    // ../src/templates/log.html,
    'Show dismissed': 'Pokaż odrzucone',

    // ../src/templates/related-add.html,src/templates/related-select-slot.html,src/templates/related-delete.html,src/templates/related-delete.html,Action title for show-related,
    'Show related objects': 'Pokaż obiekty powiązane',

    // ../src/templates/settings.html,src/templates/settings-updates.html,
    'Show release notes': 'Wersja programu',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,src/templates/clfb-chart.html,src/templates/chart.html,
    'Show table': 'Pokaż tabelę',

    // ../src/templates/related-select-slot.html,
    'Slot ': 'Slot',

    // Action title for standby,
    'Standby': 'Stan czuwania',

    // ../src/../src/core/state.js,
    'Starting the system, please wait': 'Uruchamianie systemu, proszę czekać',

    // ../src/templates/debug.html,src/templates/debug-state.html,
    'State': 'Stan',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Steps': 'Kroki',

    // ../src/templates/debug.html,
    'Tests': 'Testy',

    // ../src/../src/views/time.js,
    'The local time settings were changed successfully': 'Ustawienia czasu lokalnego zostały zmienione',

    // ../src/templates/password-reset.html,
    'The new password is': 'Nowe hasło',

    // ../src/templates/password-reset.html,
    'The password for ': 'Hasło dla',

    // ../src/../src/views/time.js,
    'The system time was changed successfully': 'Czas systemowy został zmieniony pomyślnie',

    // ../src/templates/related-add.html,
    'There are no objects to add.': 'Brak obiektów do dodania.',

    // ../src/templates/related-delete.html,
    'There are no objects to delete.': 'Brak obiektów do usunięcia.',

    // ../src/templates/related.html,
    'There are no related objects.': 'Brak powiązanych obiektów.',

    // ../src/templates/settings-updates.html,
    'There are no updates available': 'Brak aktualizacji',

    // ../src/templates/settings-install-updates.html,
    'This might take a while and the system will reboot.': 'To może chwilę potrwać, system uruchomi się ponownie.',

    // ../src/templates/settings-confirm-update.html,
    'This will reboot the entire system and cause some downtime.': 'Spowoduje to ponowne uruchomienie całego systemu i spowodowanie przestoju.',

    // ../src/templates/time.html,
    'Time': 'Czas',

    // IQ4 Text for parameter CM_TINY_TIME_RUNNING,IQ4 Text for parameter CMME_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TIME_RUNNING,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_UPTIME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_UPTIME,IQ4 Text for parameter PERFORMAX_TIME_RUNNING,IQ4 Text for parameter EASION_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TIME_RUNNING,IQ4 Text for parameter VICINION_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TIME_RUNNING,IQ4 Text for parameter BLOWFLEX_TIME_RUNNING,IQ4 Text for parameter MPM_TIME_RUNNING,
    'Time running since power up': 'Czas pracy od podłączenia',

    // IQ4 Text for parameter CMME_TIME_TO_CYCLE_OK,
    'Time to Cycle OK': 'Czas do cyklu OK',

    // ../src/templates/time.html,
    'Timezone': 'Strefa czasowa',

    // IQ4 Text for parameter GATEWAY_4_QID_TOTAL_RUNNING_TIME,IQ4 Text for parameter SEGMENT_4_QID_TOTAL_RUNNING_TIME,
    'Total running time': 'Całkowity czas pracy',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIME_RUNNING,IQ4 Text for parameter CMME_TOTAL_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TOTAL_TIME_RUNNING,IQ4 Text for parameter VICINION_TOTAL_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TOTAL_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_TOTAL_TIME_RUNNING,IQ4 Text for parameter EASION_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter MPM_TOTAL_TIME_RUNNING,
    'Total time running': 'Całkowity czas pracy',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CMME_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter THUNDERION2_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter VICINION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter IQ_COM_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CLEANFLEX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter EASION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter MPM_TOTAL_TIMES_POWERED_ON,
    'Total times powered on': 'Całkowity czas włączenia',

    // ../src/templates/log.html,src/templates/settings.html,src/templates/settings-updates.html,
    'Type': 'Rodzaj',

    // ../src/../src/views/device.js,
    'Unexpected error occured while disabling': 'Wystąpił nieoczekiwany błąd podczas wyłączania',

    // ../src/../src/views/device.js,
    'Unexpected error occured while enabling': 'Wystąpił nieoczekiwany błąd podczas włączania',

    // IQ4 Text for parameter CM_TINY_UNIQUE_DEVICE_ID,IQ4 Text for parameter CMME_UNIQUE_DEVICE_ID,IQ4 Text for parameter IQ_COM_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter SEGMENT_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_UNIQUE_DEVICE_ID,IQ4 Text for parameter EASION_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter THUNDERION2_UNIQUE_DEVICE_ID,IQ4 Text for parameter VICINION_UNIQUE_DEVICE_ID,IQ4 Text for parameter CLEANFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter BLOWFLEX_UNIQUE_DEVICE_ID,
    'Unique device ID': 'Unikalne ID urządzenia',

    // ../src/../src/core/gui.js,
    'Unknown': 'Nieznane',

    // ../src/../src/views/setting.js,../src/../src/views/setting.js,
    'Unknown IQ Object Type': 'Nieznany Typ Obiektu IQ',

    // ../src/templates/error.html,
    'Unknown error': 'Nieznany błąd',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Unknown object': 'Nieznany obiekt',

    // ../src/templates/settings-confirm-update.html,Action title for update,
    'Update': 'Aktualizacja',

    // ../src/../src/views/settings.js,../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/settings.html,
    'Updating': 'Aktualizowanie',

    // ../src/../src/core/state.js,
    'Updating the system, please wait': 'Aktualizowanie systemu, proszę czekać',

    // ../src/templates/related.html,src/templates/related.html,
    'Use the + button to add a related object.': 'Użyj przycisku +, aby dodać powiązany obiekt.',

    // ../src/templates/user.html,
    'User': 'Użytkownik',

    // ../src/../src/views/user.js,
    'User created successfully': 'Użytkownik utworzony pomyślnie',

    // ../src/../src/views/user.js,
    'User deleted successfully': 'Użytkownik usunięty pomyślnie',

    // ../src/templates/user.html,
    'User list': 'Lista użytkowników',

    // ../src/../src/views/user.js,
    'User updated successfully': 'Użytkownik zaktualizowany pomyślnie',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Username': 'Nazwa użytkownika',

    // ../src/templates/settings.html,
    'Versions': 'Wersje',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_LIMIT_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_LIMIT_60KV_MNGR4,
    'Voltage Control output current limit': 'Ograniczenie prądu wyjściowego kontroli napięcia',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_SETPOINT,
    'Voltage Control voltage setpoint': 'Napięcie Wartość zadana napięcia sterowania',

    // ../src/../src/main.js,../src/../src/core/websocket.js,
    'Waiting for server connection, please wait': 'Oczekiwanie na połączenie z serwerem, proszę czekać',

    // IQ4 Text for parameter GATEWAY_WARNING_SETPOINT,IQ4 Text for parameter GATEWAY_EX_WARNING_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_WARNING_SETPOINT,
    'Warning level, measured value over maximum': 'Ostrzeżenie, wartość zmierzona pow. maximum',

    // IQ4 Text for parameter GATEWAY_WARNING_MINIMUM,IQ4 Text for parameter GATEWAY_EX_WARNING_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_WARNING_MINIMUM,
    'Warning level, measured value under minimum': 'Ostrzeżenie, wartość zmierzona poniżej minimum',

    // IQ4 Text for parameter GATEWAY_WARNING_ERROR_DELAY,IQ4 Text for parameter GATEWAY_EX_WARNING_ERROR_DELAY,
    'Warning/alarm delay': 'Opóźnienie ostrzeżenia/alarmu',

    // ../src/templates/log.html,
    'Warnings': 'Ostrzeżenia',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_SPEED,IQ4 Text for parameter EASION_WEB_SPEED,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_SPEED,IQ4 Text for parameter PERFORMAX_WEB_SPEED,
    'Web speed': 'Prędkość wstęgi',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,
    'Web voltage average (all)': 'Napięcie średnie wstęgi (wszystko)',

    // IQ4 Text for setting CLFB_WEBVOLTAGEOFFSET,
    'Web voltage offset': 'Napięcie wstęgi offset',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG1,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG1,
    'Web voltage seg1': 'Napięcie wstęgi seg1',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG10,
    'Web voltage seg10': 'Napięcie wstęgi seg10',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG11,
    'Web voltage seg11': 'Napięcie wstęgi seg11',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG12,
    'Web voltage seg12': 'Napięcie wstęgi seg12',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG13,
    'Web voltage seg13': 'Napięcie wstęgi seg13',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG14,
    'Web voltage seg14': 'Napięcie wstęgi seg14',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG15,
    'Web voltage seg15': 'Napięcie wstęgi seg15',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG16,
    'Web voltage seg16': 'Napięcie wstęgi seg16',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG2,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG2,
    'Web voltage seg2': 'Napięcie wstęgi seg2',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG3,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG3,
    'Web voltage seg3': 'Napięcie wstęgi seg3',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG4,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG4,
    'Web voltage seg4': 'Napięcie wstęgi seg4',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG5,
    'Web voltage seg5': 'Napięcie wstęgi seg5',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG6,
    'Web voltage seg6': 'Napięcie wstęgi seg6',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG7,
    'Web voltage seg7': 'Napięcie wstęgi seg7',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG8,
    'Web voltage seg8': 'Napięcie wstęgi seg8',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG9,
    'Web voltage seg9': 'Napięcie wstęgi seg9',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_WIDTH,IQ4 Text for parameter EASION_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_WEB_WIDTH,
    'Web width': 'Szerokość wstęgi',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'Yes': 'Tak',

    // ../src/templates/user.html,
    'You are currently logged in as the user': 'Jesteś obecnie zalogowany jako użytkownik',

    // ../src/../src/views/user.js,
    'You can not delete your own user account': 'Nie możesz usunąć swojego własnego konta',

    // IQ4 Text for event WARNING_MASK_ARC_DETECTED,IQ4 Text for event ALARM_MASK_ARC_DETECTED,
    'arc detected': 'wykryto łuk elektryczny',

    // IQ4 Text for event WARNING_EFFICIENCY_LOW,
    'bar efficiency low': 'niska efektywność listwy',

    // IQ4 Text for event WARNING_CALIBRATION_IN_PROGRESS,
    'calibration in progress': 'kalibracja w toku',

    // IQ4 Text for event WARNING_CALIBRATION_INVALID,
    'calibration invalid': 'niepoprawna kalibracja',

    // IQ4 Text for event WARNING_MASK_CHARGEPULSE_TOO_LONG,
    'chargepulse too long': 'impuls ładowania zbyt długi',

    // IQ4 Text for event WARNING_MASK_CLEAN_BAR,
    'clean bar': 'wyczyść listwę',

    // IQ4 Text for event ALARM_COMMUNICATION_LOST_SEGMENT,
    'communication lost with sensor segment': 'brak komunikacji z segmentem czujników',

    // IQ4 Text for event WARNING_MASK_HMI_COMM_LOST,
    'device communication lost': 'utracono komunikację z urządzeniem',

    // IQ4 Text for event WARNING_MASK_DEVICE_NEEDS_REBOOT,
    'device needs reboot': 'urządzenie wymaga ponownego uruchomienia',

    // IQ4 Text for event SYS_WARNING_MASK_DEVICE_PORT_CHANGED,IQ4 Text for event WARNING_PORT_SWAP,
    'device port changed': 'port urządzenia zmieniony',

    // IQ4 Text for event ALARM_MASK_EEPROM_DEFECTIVE,
    'eeprom defective': 'uszkodzenie eeprom',

    // IQ4 Text for event WARNING_MASK_EEPROM_PREV_SETTINGS_REST,
    'eeprom previous settings restored': 'przywrócono poprzednie ustawienia eeprom',

    // IQ4 Text for event WARNING_MASK_EEPROM_TOO_MANY_WRITES,
    'eeprom too many write cycles': 'eeprom za dużo cykli zapisu',

    // IQ4 Text for event WARNING_MASK_GENERATOR_NOT_CONNECTED,
    'generator not connected': 'generator nie podłączony',

    // ../src/templates/password-reset.html,
    'has been reset': 'został zresetowany',

    // IQ4 Text for event WARNING_TEMPERATURE_HIGH,IQ4 Text for event WARNING_MASK_HIGH_TEMP,
    'high temperature': 'wysoka temperatura',

    // IQ4 Text for event WARNING_COMMUNICATION_LOST,
    'hmi communication lost': 'utracono komunikację hmi',

    // ../src/templates/log-table.html,
    'inactive': 'nieaktywny',

    // IQ4 Text for event SYS_WARNING_MASK_NEW_DEVICE_CONNECTED,IQ4 Text for event WARNING_NEW_DEVICE_CONNECTED,
    'new device connected': 'podłączono nowe urządzenie',

    // IQ4 Text for event WARNING_MASK_NO_ACTIVE_SEGMENTS,IQ4 Text for event WARNING_LINKED_SENSOR_NO_ACTIVE_SEGMENTS,
    'no active sensor segments': 'brak aktywnych segmentów czujników',

    // IQ4 Text for event WARNING_MASK_NO_COMM_PAIRED_DEVICE,IQ4 Text for event WARNING_LINKED_SENSOR_NO_COMM_PAIRED_DEVICE,
    'no communication with paired device': 'brak komunikacji z sparowanym urządzeniem',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_LOW,IQ4 Text for event ALARM_MASK_LEVEL_TOO_LOW,
    'output curent too low': 'niskie natężenie wyjściowe',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_HIGH,IQ4 Text for event ALARM_MASK_LEVEL_TOO_HIGH,
    'output current too high': 'wysokie natężenie wyjściowe',

    // IQ4 Text for event WARNING_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event ALARM_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event WARNING_HVOUT_OVERLOAD,IQ4 Text for event ALARM_HVOUT_OVERLOAD,
    'overload high voltage': 'przeciążanie wysokiego napięcia',

    // IQ4 Text for event WARNING_MASK_PAIRED_DEVICE_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_PAIRED_DEVICE_MISSING,
    'paired device missing': 'brak sparowanego urządzenia',

    // IQ4 Text for event WARNING_MASK_SENSOR_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_SEGMENT_MISSING,
    'paired sensor missing': 'brak sparowanego sensora',

    // IQ4 Text for event WARNING_MASK_POWER_SUPPLY_LOW,
    'power supply low': 'niski poziom zasilania',

    // IQ4 Text for event ALARM_SENSOR_MALFUNCTION,
    'sensor malfunction': 'awaria sensora',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'source': 'target',

    // IQ4 Text for event ALARM_SUPPLY_CURRENT_HIGH,IQ4 Text for event ALARM_MASK_HIGH_PRIM_CUR,
    'supply current too high': 'natężenie zasilania zbyt wysokie',

    // IQ4 Text for event WARNING_SUPPLY_VOLTAGE_LOW,
    'supply voltage too low': 'napięcie zasilania zbyt niskie',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'test': 'Test',

    // IQ4 Text for event ALARM_MASK_HIGH_TEMP,IQ4 Text for event ALARM_TEMPERATURE_HIGH,
    'too high temperature': 'za wysoka temperatura',

    // ../src/templates/user.html,
    'userlevel': 'poziom użytkownika',

    // ../src/templates/user.html,
    'username': 'nazwa użytkownika',

    // IQ4 Text for event ALARM_WEB_VOLTAGE_OVERLOAD,
    'web voltage overload': 'Przeciążenie napięciem sieci',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_HIGH_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_HIGH_ERROR,
    'web voltage too high': 'Zbyt wysokie napięcie sieci',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_LOW_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_LOW_ERROR,
    'web voltage too low': 'Zbyt niskie napięcie sieci',

    // ====================================================
    // TODO: translate these
    // ====================================================
    // ../src/../src/debugger/testrunner.js, 
    'Error: could not find test': 'Error: could not find test', 

    // ../src/../src/debugger/debugger.js, 
    'A test is already being recorded': 'A test is already being recorded', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Stop recording': 'Stop recording', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Record new test': 'Record new test', 

    // ../src/../src/debugger/debugger.js, 
    'Are you sure you want to delete this test?': 'Are you sure you want to delete this test?', 

    // ../src/../src/views/setting.js, 
    'Failed to retrieve this CLFB\'s input device': 'Failed to retrieve this CLFB\'s input device', 

    // ../src/../src/views/action.js, 
    'Before exporting the Fieldbus configuration you first have to enable it.': 'Before exporting the Fieldbus configuration you first have to enable it.', 

    // ../src/../src/views/action.js, 
    ' Please check the device help for more information.': ' Please check the device help for more information.', 

    // ../src/../src/views/related.js, ../src/../src/views/related.js, 
    'You can not add a related object to a fieldbus while the fieldbus is running': 'You can not add a related object to a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not remove a related object from a fieldbus while the fieldbus is running': 'You can not remove a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not delete a related object from a fieldbus while the fieldbus is running': 'You can not delete a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'Can not delete unknown related object': 'Can not delete unknown related object', 

    // ../src/../src/views/parameter.js, 
    'Your number is less than the minimum value: ': 'Your number is less than the minimum value: ', 

    // ../src/../src/views/parameter.js, 
    'Your number is greater than the maximum value: ': 'Your number is greater than the maximum value: ', 

    // ../src/../src/core/state.js, 
    'Error saving the user preferences': 'Error saving the user preferences', 

    // ../src/templates/clfb-io.html, 
    'CLFB I/O': 'CLFB I/O', 

    // ../src/templates/clfb-io.html, src/templates/parameter.html, 
    'Record changes': 'Record changes', 

    // ../src/templates/macros.html, 
    'Unknown input': 'Unknown input', 

    // ../src/templates/macros.html, 
    'Unknown output': 'Unknown output', 

    // ../src/templates/active-users.html, 
    'Active user sessions': 'Active user sessions', 

    // ../src/templates/active-users.html, 
    'User name': 'User name', 

    // ../src/templates/active-users.html, 
    'User level': 'User level', 

    // ../src/templates/active-users.html, 
    'Go to dashboard': 'Go to dashboard', 

    // ../src/templates/debug.html, 
    'Debug': 'Debug', 

    // ../src/templates/debug.html, 
    'Restart': 'Restart', 

    // ../src/templates/debug-report.html, 
    'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.': 'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'New test name': 'New test name', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'Test': 'Test', 

    // ../src/templates/debug-test.html, 
    'Rename': 'Rename', 

    // ../src/templates/debug-test.html, 
    'Run recorded test selection': 'Run recorded test selection', 

    // ../src/templates/time.html, 
    '24H': '24H', 

    // ../src/templates/time.html, 
    'AM/PM': 'AM/PM', 

    // ../src/templates/settings.html, src/templates/settings-updates.html, 
    'UID': 'UID', 

    // ../src/templates/customize-view.html, 
    'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".': 'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".', 

    // ../src/templates/user.html, 
    'id': 'id', 

    // IQ4 Text for parameter CM_TINY_EEPROM_WRITE_COUNTS, IQ4 Text for parameter MPM_EEPROM_WRITE_COUNTS, 
    'Eeprom write counts': 'Eeprom write counts', 

    // IQ4 Text for parameter MPM_OPERATION_MODE, 
    'Operation mode (dipswitch 3)': 'Operation mode (dipswitch 3)', 

    // IQ4 Text for parameter MPM_REMOTE_ON_OFF_SOURCE, 
    'Remote on/off source (dipswitch 1)': 'Remote on/off source (dipswitch 1)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT, 
    'Analog input (dipswitch 2)': 'Analog input (dipswitch 2)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FUNCTION, 
    'Analog input function': 'Analog input function', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FEEDBACK, 
    'Analog input': 'Analog input', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_OFFSET, 
    'Calibrate analog input offset': 'Calibrate analog input offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_GAIN, 
    'Calibrate analog input gain': 'Calibrate analog input gain', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_FEEDBACK, 
    'High voltage output': 'High voltage output', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_OFFSET, 
    'Calibrate output voltage feedback offset': 'Calibrate output voltage feedback offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_GAIN, 
    'Calibrate output voltage feedback gain': 'Calibrate output voltage feedback gain', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_OFFSET, 
    'Calibrate output current offset': 'Calibrate output current offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_GAIN, 
    'Calibrate output current gain': 'Calibrate output current gain', 

    // IQ4 Text for parameter MPM_MAX_QUANTITY_ARCS, 
    'Max. arcs per minute': 'Max. arcs per minute', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT_MAX, 
    'Max. output voltage': 'Max. output voltage', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT, 
    'Output voltage setpoint': 'Output voltage setpoint', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_OFFSET, 
    'Calibrate output voltage setpoint offset': 'Calibrate output voltage setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_GAIN, 
    'Calibrate output voltage setpoint gain': 'Calibrate output voltage setpoint gain', 

    // IQ4 Text for parameter MPM_CURRENT_LIMIT_SETPOINT, 
    'Max. output current': 'Max. output current', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_OFFSET, 
    'Calibrate output current setpoint offset': 'Calibrate output current setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_GAIN, 
    'Calibrate output current setpoint gain': 'Calibrate output current setpoint gain', 

    // IQ4 Text for parameter MPM_BALANCE_OFFSET, 
    'Balance offset': 'Balance offset', 

    // IQ4 Text for parameter GATEWAY_IQ4_WEB_DISTANCE_SEG1, 
    'Web distance segment 1': 'Web distance segment 1', 

    // IQ4 Text for parameter MPM_UNIQUE_DEVICE_ID, 
    'Unique device id': 'Unique device id', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MIN, 
    'Power supply warning level min.': 'Power supply warning level min.', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MAX, 
    'Power supply warning level max.': 'Power supply warning level max.', 

    // IQ4 Text for parameter MPM_AUTORUN, 
    'Autorun': 'Autorun', 

    // IQ4 Text for parameter MPM_FREE_RAM_CNTR, 
    'Free RAM counter': 'Free RAM counter', 

    // IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_MAX, 
    'Output voltage max': 'Output voltage max', 

    // IQ4 Text for setting CLFB_ACTIVESEGMENTS, 
    'Active Segments': 'Active Segments', 

    // IQ4 Text for event WARNING_NO_SEGMENTS_SELECTED, 
    'No sensor segments selected': 'No sensor segments selected', 

    // IQ4 Text for event Test, 
    'Dit is een test': 'Dit is een test', 

};

export function getTranslations() {
    return translations;
}