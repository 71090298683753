/**
 * Action view module.
 * @module action
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Permissions from '../core/permissions';
import * as Signals from '../core/signals';
import * as State from '../core/state';

export class ActionView {
    constructor() {
        this.viewName = 'ActionView';
        this.iqObject = null;
        this.action = null;
        this.actionHandlerIds = [];
        this.listenerIds = [];
    }

    destructor() {
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    performAction(actionId, args, elem) {
        $(elem).prop('disabled', true);
        Gui.enableLoadingIcon(elem);
        const parts = actionId.split('-');
        const uid = parts[0];
        const actionName = parts[1];

        let apiCall = Api.executeAction;

        if(Helpers.isFieldbus(this.iqObject)) {
            if(actionName === 'export') {
                if(!Helpers.arrayContains(
                    State.getIqObject(this.iqObject.uid).status.mode,
                    ['running', 'starting', 'waiting']
                )) {
                    $(elem).prop('disabled', false);
                    Gui.disableLoadingIcon(elem);
                    return Messages.addError(129, _('Before exporting the Fieldbus configuration you first have to enable it.'));
                }
            }

            apiCall = Api.executeFieldbusAction;
        } else if(Helpers.isClfb(this.iqObject)) {
            apiCall = Api.executeClfbAction;
            if(actionName === 'remove') {
                apiCall = Api.deleteClfb;
            }
        }

        apiCall(uid, actionName).then(() => {
            $(elem).prop('disabled', false);
            Gui.disableLoadingIcon(elem);
            Gui.showButtonDoneState(elem);
        }).catch((e) => {
            let msg = 'Error';
            $(elem).prop('disabled', false);

            if(e.hasOwnProperty('responseJSON')) {
                const response = e.responseJSON;
                if(response.hasOwnProperty('message')) {
                    msg = response.message;
                }
            }

            let fullErrorMessage = (
                _('Failed to perform the action ') +
                '"' + this.action.name + '".'
            );

            if(msg !== 'Error') {
                fullErrorMessage += msg;
            }

            if(Helpers.isDevice(this.iqObject) && Help.hasDeviceHelp(this.iqObject)) {
                fullErrorMessage += _(' Please check the device help for more information.');
                Messages.addError(124, fullErrorMessage, '/#/device/' + this.iqObject.uid + '/help');
            } else {
                Messages.addError(124, fullErrorMessage);
            }

            Gui.disableLoadingIcon(elem);
            Gui.showButtonErrorState(elem, msg, true);
        });
    }

    toggleHelp() {
        Gui.toggleHelpSection();
    }

    closeHelp() {
        Gui.closeHelpSection();
    }

    registerActionHandlers(){
        Debug.log('Register action handlers');

        if(!Permissions.canPerformAction(this.iqObject, this.action.name)){
            return;
        }

        const id = this.iqObject.uid + '-' + this.action.name;

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
               id, 'perform-action', this.performAction.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'toggle-help', this.toggleHelp.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'close-help', this.closeHelp.bind(this)
            )
        );
    }

    /**
     * Initialization, retrieves the IQ Object info from the api
     * and sets up all the instance variables. This function is used
     * in the render and edit functions
     * @param {string} uid - the IQ Object UID
     * @param {string} id - the action ID
     * @param {function} callback - called when everything is
     *                              initialized successfully
     * @private
     */
    initialize(uid, id, callback) {
        let apiCall = Api.getDevice;
        const obj = State.getIqObject(uid);
        if(Helpers.isFieldbus(obj)) {
            apiCall = Api.getFieldbus;
        } else if(Helpers.isClfb(obj)) {
            apiCall = Api.getClfb;
        }

        apiCall(uid).then((iqObject) => {
            this.iqObject = iqObject;
            Debug.log('IQ Object info:');
            Debug.log(this.iqObject);

            const numericId = parseInt(id);

            for(let i = 0; i < this.iqObject.actions.length; i++){
                const action = this.iqObject.actions[i];
                if(action.id === numericId){
                    this.action = action;
                    break;
                }
            }

            if(this.action === null){
                return Gui.show404();
            }

            let objectState = State.getIqObject(this.iqObject.uid);

            this.iqObject.position = objectState.position;
            this.iqObject.name = objectState.name;

            this.registerActionHandlers();
            callback();
        }).catch((e) => {
            Debug.log(e);
            return Gui.show404();
        });
    }

    /**
     * Handler for when this action's IQ object is removed
     */
    objectRemoved(msg) {
        if(this.iqObject === null) {
            return window.visit('/#/');
        }
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.iqObject.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    /**
     * Render this action view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args, perform=false) {
        if(!args.hasOwnProperty('id') || !args.hasOwnProperty('id')){
            return Gui.show404();
        }

        Debug.log(
            'Render action view for IQ Object ' +
            args.uid +
            ', and action ' +
            args.id
        );

        this.listenerIds.push(
            Signals.addListener('object/removed', this.objectRemoved.bind(this))
        );

        this.initialize(args.uid, args.id, () => {
            Debug.log('Action details');
            Debug.log(this.action);
            let context = {
                'iqObject': this.iqObject,
                'action': this.action,
                'helpText': '',
            };

            Help.action(this.action.name).then((helpText) => {
                context.helpText = helpText;
            }).catch(()=>{}).finally(() => {
                Gui.render('action.html', context);
                if(perform) {
                    const id = this.iqObject.uid + '-' + this.action.name;
                    this.performAction(id, [], Gui.getActionElementById(id));
                }
            });
        });
    }

    perform(event, args) {
        this.render(event, args, true);
    }
}
