/**
 * Debug Log Module
 * @module DebugLog
 */

"use strict";

import * as Helpers from '../core/helpers';
import * as State from '../core/state';
import * as Api from '../core/api';
import * as Gui from '../core/gui';

export let logHistory = [];
export let consoleLogEnabled = false;
export let active = false;

/**
 * Toggle console loggin
 * @param {Event} event
 */
export function toggleConsoleLog(event) {
    consoleLogEnabled = $(event.currentTarget).is(':checked');
}

/**
 * Show a log message and add to log history if debugging is enabled
 * @param {any} msg - the log message
 * @param {boolean} record - whether to record this message in the log history
 * @param {number} type - this log's type, used for filtering debug messages
 *    0 - Debug message
 *    1 - Info message
 *    2 - Warning message
 *    3 - Error message
 */
export function log(msg, record=true, type=0) {
    if(!window.DEBUG && !window.CONFIG.hasOwnProperty('alwaysShowLog')) {
        return;
    }

    if(consoleLogEnabled || window.CONFIG.hasOwnProperty('alwaysShowLog')) {
        console.log(msg);
    }

    let msgString = '';

    if(Helpers.checkType('object', msg)) {
        msgString = '<details><summary>Object</summary><pre>';
        msgString += JSON.stringify(msg, null, 2);
        msgString += '</pre></details>';
    } else {
        msgString = msg;
    }

    if(record) {
        const date = new Date();
        const historyItem = {
            time: + date,
            prettyTime: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() +
                ' ' + Helpers.padZero(date.getHours().toString(), 2) + ':' + Helpers.padZero(date.getMinutes().toString(), 2) + ':' +
                Helpers.padZero(date.getSeconds().toString(), 2),
            msg: msgString,
            type: type,
        };

        Api.sendLogMessage(type, msg);

        logHistory.push(historyItem);

        while(logHistory.length > 30) {
            logHistory.shift();
        }

        const logTypeMap = {
            0: 'Debug',
            1: 'Info',
            2: 'Warning',
            3: 'Error',
        };

        let itemType = 'Debug';

        if(logTypeMap.hasOwnProperty(historyItem.type)) {
            itemType = logTypeMap[historyItem.type];
        }

        if(active) {
            let html = '<tr>';
            html += '<td>' + historyItem.prettyTime + '</td>';
            html += '<td>' + historyItem.msg + '</td>';
            html += '<td>' + itemType + '</td>';
            html += '</tr>';
            $('div.debug-log table tbody').prepend(html);
            // gt is 0 indexed
            $('div.debug-log table tbody tr:gt(29)').remove();
        }
    }
}

export function setActive() {
    active = true;
}

export function setInactive(){
    active = false;
}

export function clearLog(){
    logHistory = [];
}

export function printLog() {
    let html = '<table class="debug-log responsive" cellspacing="5" cellpadding="5" width="100%">';
    html += '<thead><th>Time</th><th>Message</th><th>Type</th></thead>';
    html += '<tbody>';

    const logTypeMap = {
        0: 'Debug',
        1: 'Info',
        2: 'Warning',
        3: 'Error',
    };

    for(let i = logHistory.length - 1; i >= 0; i--) {
        const historyItem = logHistory[i];
        let itemType = 'Debug';

        if(logTypeMap.hasOwnProperty(historyItem.type)) {
            itemType = logTypeMap[historyItem.type];
        }

        html += '<tr>';
        html += '<td>' + historyItem.prettyTime + '</td>';
        html += '<td>' + historyItem.msg + '</td>';
        html += '<td>' + itemType + '</td>';
        html += '</tr>';
    }
    html += '</thead>';
    html += '</table>';

    $('div.debug-log').html(html);
}

export function getLogHistory() {
    return logHistory;
}

/**
 * Download the IQ4 data logs as a zip file
 * @param {'data' | 'system'} logType
 * @returns function
 */
export function downloadLogs(logType) {
    return (event) => {
        event.preventDefault();
        event.stopPropagation();
        const oldButtonValue = $(event.currentTarget).val();
        $(event.currentTarget).val(_('Retrieving logs, please wait'));
        $(event.currentTarget).prop('disabled', true);

        const now = new Date();
        const month = Helpers.padZero((now.getMonth() + 1).toString(), 2);
        const dateStr = (
            now.getFullYear() + '-' +
            month + '-' +
            now.getDate() + '_' +
            now.getHours() + '_' +
            Helpers.padZero(now.getMinutes().toString(), 2)
        );

        const reset = () => {
            $(event.currentTarget).prop('disabled', false);
            $(event.currentTarget).val(oldButtonValue);
        };

        const attemptDownload = () => {
            Api.getLogs(logType).then((blob) => {
                reset();
                return saveAs(
                    blob,
                    'iq4-' + logType + '-logs-' + dateStr + '.zip'
                );
            }).catch((err) => {
                if(err === 425) {
                    window.setTimeout(attemptDownload, 1000);
                } else {
                    reset();
                    alert(_('Failed to download logs'));
                }
            });
        };

        Api.initiateLogDownload(logType).then(() => {
            window.setTimeout(attemptDownload, 1000);
        }).catch(err => {
            if(err.status === 429) {
                window.setTimeout(attemptDownload, 1000);
            } else {
                reset();
                alert(_('Failed to download logs'));
            }
        });
    };
}
