/**
 * Main module.
 * @module main
 */

"use strict";

import * as Actions from './core/actions';
import * as Debugger from './debugger/debugger';
import * as Events from './core/events';
import * as Gui from './core/gui';
import * as Helpers from './core/helpers';
import * as I8n from './core/i8n';
import * as Routing from './routing';
import * as Session from './core/session';
import * as Signals from './core/signals';
import * as State from './core/state';
import * as Websocket from './core/websocket';
import * as Debug from './core/debug';
import * as Messages from './core/messages';

window.DEBUG = false;
window.INIT_DEBUGGER = Debugger.init;
window.REMOVE_DEBUGGER = Debugger.remove;
window.CURRENT_CONFIG = Helpers.clone(window.CONFIG);

let initialized = false;

Gui.initGui();
Gui.render('loading.html');
Session.initSession();
I8n.initTranslations();

/**
 * Callback that is executed after the websocket connection is established
 * successfully. Might be called again after recovering from a websocket
 * failure.
 */
function postWebsocketConnection() {
    // If we have already gone through initialization process, we can simply
    // refresh the current page
    if(initialized) {
        return Routing.refresh();
    }

    Events.initEvents();
    Actions.initActions();
    Routing.initRouting();
    initialized = true;

    Signals.quickEmit('internal/hide-sub-override-screen');

    const initState = () => {
        Debug.log('State is initialized, device and fieldbus lists retrieved');
        Debug.log(State.getDevices(), false);
        Debug.log(State.getFieldbuses(), false);
        Debug.log(State.getClfbs(), false);
        Gui.updateUserName(Session.getLoginData());
        Gui.showIcons();

        Gui.refreshStateList();

        const path = window.location.pathname + window.location.hash;
        if(path === '/' || path === '/#/') {
            Routing.visit('/');
            Routing.visit('/#/screensaver', null, {}, false, true);
        } else {
            Routing.visit(path);
        }
    };

    State.initState().then(initState).catch((err) => {
        if(err.status === 403 || err.status === 401){
            Routing.visit('/#/login');
        } else {
            Gui.render('error.html', {
                'message': 'Failed to initialize state, trying again in 5 seconds'
            });
            Debug.log(err);
            // Try again to initialize the state in 5 seconds
            window.setTimeout(initState, 5000);
        }
    });
}

/**
 * Callback that is executed after websocket connection failure. The websocket
 * module tries to reconnect automatically, so that's no concern of this
 * function.
 */
function postConnectionFailure() {
    Debug.log(
        'Failed to connect to websocket with these settings: ' +
        JSON.stringify(window.CONFIG)
    );

    Signals.quickEmit(
        'internal/show-sub-override-screen',
        _('Waiting for server connection, please wait')
    );
}

Websocket.initWebsocket(postWebsocketConnection, postConnectionFailure);
