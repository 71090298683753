/**
 * Widget
 * This is the base class for all widgets.
 * @module widget
 */

"use strict";

import * as Helpers from '../core/helpers';

export class Widget {
    constructor() {
        this.id = Helpers.shortRandomId();
    }

    destructor() {}

    addContext(...arr) {}

    render(parentElem) {
        this.parentElem = parentElem;
    }

    resetSize() {}

    update() {}

}
