/**
 * State module.
 * The State module keeps track of all the devices and their states.
 * This module only keeps a shallow copy of the device data. For retrieving the
 * full device description including a list of all the parameters you need to
 * call the API.
 * Whenever there's an update from the manager the tracked state is updated and
 * uses as a reference for updating the screens.
 * @module state
 */

"use strict";

import * as Api from './api';
import * as Helpers from './helpers';
import * as Debug from './debug';
import * as Signals from './signals';
import * as Messages from '../core/messages';
import * as Session from './session';

let state = {
    'devices': [],
    'fieldbuses': [],
    'clfbs': [],
    'globalPermissions': {},
    'managerStatus': {
        'status': 'running',
        'globalstatus': '',
        'heartbeat': 0,
        'update': {
            'status': 'normal',
            'target': '',
        },
    },
    'time': {
        'current': -1,
        'timeZoneOffset': - new Date().getTimezoneOffset(),
        'use24hours': true,
    },
    'customObjectOrder': [],
    'recordedTests': [],
    'objectOrderMode': 'type',
};

/**
 * Initialize the state module. Retrieve a list of devices from the API.
 * @returns {Promise}
 */
export function initState() {
    Debug.log('Initializing state module');
    Signals.addListener('device/registered', updateDeviceList);
    Signals.addListener('fieldbus/registered', updateFieldbusList);
    Signals.addListener('clfb/registered', updateClfbList);
    Signals.addListener('manager/status', updateManagerStatus);
    Signals.addListener('object/removed', objectRemoved);
    Signals.addListener('manager/time', processTimeMessage);

    Api.getPreferences().then((preferences) => {
        if(preferences.hasOwnProperty('customObjectOrder')) {
            state.customObjectOrder = preferences.customObjectOrder;
        }
        if(preferences.hasOwnProperty('objectOrderMode')) {
            state.objectOrderMode = preferences.objectOrderMode;
        }

        if(preferences.hasOwnProperty('timeZoneOffset') && Helpers.isNumeric(preferences.timeZoneOffset)) {
            state.time.timeZoneOffset = parseInt(preferences.timeZoneOffset);
        }

        if(preferences.hasOwnProperty('use24hours')) {
            let raw24hours = preferences.use24hours;
            state.time.use24hours = preferences.use24hours === true;
        } else {
            const hr = new Intl.DateTimeFormat([], {hour: "numeric"}).format();
            state.time.use24hours = Number.isInteger(Number(
                new Intl.DateTimeFormat([], {hour: "numeric"}).format()
            ));
        }

        if(preferences.hasOwnProperty('recordedTests')) {
            state.recordedTests = preferences.recordedTests;
        }

        updateMomentTimezone();
    }).catch((err) => {
        Debug.log('Failed to retrieve and process preferences');
    });

    return Promise.all([
        updateDeviceList(),
        updateFieldbusList(),
        updateClfbList(),
        setGlobalPermissions(),
        setManagerStatus()
    ]);
}

function updateMomentTimezone() {
    let unpacked = {
        name       : 'Simco',
        abbrs      : ['SMC'],
        offsets    : [-state.time.timeZoneOffset],
        untils     : [null],
        population : 1,
    };
    moment.tz.add(moment.tz.pack(unpacked));
    moment.tz.setDefault('Simco');
}

/**
 * Update user preferences
 */
function updatePreferences() {
    const preferences = {
        customObjectOrder: state.customObjectOrder,
        objectOrderMode: state.objectOrderMode,
        timeZoneOffset: state.time.timeZoneOffset,
        use24hours: state.time.use24hours,
        recordedTests: state.recordedTests,
        version: 2,
    };

    updateMomentTimezone();

    Api.updatePreferences(preferences).then((data) => {
        Debug.log('Preferences updated');
        Debug.log(preferences);
    }).catch((err) => {
        Messages.addError(125, _('Error saving the user preferences'));
    });
}

/**
 * Updates the device list using the API
 * @returns {Promise}
 */
export function updateDeviceList() {
    return new Promise((resolve, reject) => {
        Debug.log('Retrieving list of devices');
        Api.getDevices().then((data) => {
            Debug.log('Retrieved list of devices');
            state.devices = data;
            Signals.emit({
                'tag': 'internal/new-device-list'
            });
            Debug.updateState(state);
            return resolve();
        }).catch((err) => {
            Debug.log('Failed to retrieve list of devices');
            return reject(err);
        });
    });
}

/**
 * Updates the fieldbuses list using the API
 * @returns {Promise}
 */
export function updateFieldbusList() {
    return new Promise((resolve, reject) => {
        Debug.log('Retrieving list of fieldbuses');
        Api.getFieldbuses().then((data) => {
            Debug.log('Retrieved list of fieldbuses');
            state.fieldbuses = data;
            Signals.emit({
                'tag': 'internal/new-fieldbus-list'
            });
            Debug.updateState(state);
            return resolve();
        }).catch((err) => {
            Debug.log('Failed to retrieve list of fieldbuses');
            Debug.log(err);
            return reject(err);
        });
    });
}


/**
 * Updates the clfb list using the API
 * @returns {Promise}
 */
export function updateClfbList() {
    return new Promise((resolve, reject) => {
        Debug.log('Retrieving list of clfbs');
        Api.getClfbs().then((data) => {
            Debug.log('Retrieved list of clfbs');
            state.clfbs = data;
            Signals.emit({
                'tag': 'internal/new-clfb-list'
            });
            Debug.updateState(state);
            return resolve();
        }).catch((err) => {
            Debug.log('Failed to retrieve list of clfbs');
            Debug.log(err);
            return reject(err);
        });
    });
}

function _showOverrideScreen(message) {
    Debug.log('Show override screen with message: ' + message);
    return Signals.emit({
        'tag': 'internal/show-override-screen',
        'data': {
            'msg': message,
        }
    });
}

function _hideOverrideScreen() {
    Debug.log('Hide override screen');
    return Signals.emit({
        'tag': 'internal/hide-override-screen',
        'data': {}
    });
}

function updateManagerStatus(msg) {
    const oldStatus = Helpers.clone(state.managerStatus);
    const newStatus = msg.data;
    state.managerStatus = newStatus;

    if(newStatus.hasOwnProperty('heartbeat')) {
        setTime(newStatus.heartbeat);
    }

    if(newStatus.status === oldStatus.status) {
        if(newStatus.status !== 'updating') {
            return;
        }

        if(newStatus.update.status === oldStatus.update.status &&
            newStatus.update.target === oldStatus.update.target
        ){
            return;
        }
    }

    if(oldStatus.status === 'updating' && oldStatus.status !== newStatus.status) {
        Signals.emit({
            tag: 'internal/close-update-message-bar',
            data: newStatus.update,
        });
    }

    if(newStatus.status === 'running') {
        return _hideOverrideScreen();
    }

    if(newStatus.status === 'starting') {
        return _showOverrideScreen(_('Starting the system, please wait'));
    }

    if(newStatus.status === 'restarting') {
        return _showOverrideScreen(_('Restarting, please wait'));
    }

    if(newStatus.status === 'updating') {
        if(Helpers.arrayContains(newStatus.update.status, ['device', 'slc'])) {
            if(!Helpers.arrayContains(Session.getUserLevel(), ['basic', 'advanced'])) {
                if(oldStatus.status !== 'updating') {
                    // Update for device or slc initiated
                    Signals.emit({
                        tag: 'internal/show-update-message-bar',
                        data: newStatus.update,
                    });
                } else if(
                    oldStatus.update.status !== newStatus.update.status ||
                    oldStatus.update.target !== newStatus.update.target
                ) {
                    // Update target switched between device/slc, or new target
                    Signals.emit({
                        tag: 'internal/refresh-update-message-bar',
                        data: newStatus.update,
                    });
                }
            }

            return _hideOverrideScreen();
        }

        if(newStatus.update.status === 'system') {
            return _showOverrideScreen(_('Updating the system, please wait'));
        }

        if(newStatus.update.status === 'restart') {
            return _showOverrideScreen(_('Restarting the system, please wait'));
        }
    }

}

/**
 * Retrieve the list of global permissions from the API and keep them in the
 * state object
 * @returns {Promise}
 */
function setGlobalPermissions() {
    return new Promise((resolve, reject) => {
        Api.getGlobalPermissions().then((globalPermissions) => {
            Debug.log('Retrieved global permissions');
            Debug.log(globalPermissions);
            if(!Helpers.checkType('array', globalPermissions)) {
                return reject('Global permissions from API have the wrong type (expected array)');
            }
            for(const perm of globalPermissions) {
                if(!perm.hasOwnProperty('name') || !perm.hasOwnProperty('accessLevels')) {
                    continue;
                }
                state.globalPermissions[perm.name] = perm.accessLevels;
            }
            Debug.updateState(state);
            return resolve();
        }).catch((err) => {
            // TODO: reject this when manager has implemented global
            // permissions
            Debug.log('Failed to setup global permissions, but not failing on that yet');
            Debug.log(err);
            return resolve();
            // return reject(err);
        });
    });
}

function setManagerStatus() {
    return new Promise((resolve, reject) => {
        Api.getManagerStatus().then((managerStatus) => {
            Debug.log('Retrieved global status');
            Debug.log(managerStatus);
            updateManagerStatus({data: managerStatus});
            return resolve();
        }).catch((err) => {
            Debug.log('Failed to setup global status');
            return reject(err);
        });
    });
}

/**
 * Get a list of all the currently known devices
 * @returns {array} - Device list
 */
export function getDevices() {
    return state.devices;
}

/**
 * Get a list of all the currently known fieldbuses
 * @returns {array} - Fieldbus list
 */
export function getFieldbuses() {
    return state.fieldbuses;
}

/**
 * Get a list of all the currently known clfbs
 * @returns {array} - Clfb list
 */
export function getClfbs() {
    return state.clfbs;
}

/**
 * Get a list of IQ Objects currently known to the system. You can check
 * the types by looking at the _meta.type property of each object in the list.
 * You can supply a list of uids if you only want a subset.
 * @param {array|false} uids - optional list of uids
 * @returns {array} - List of IQ Objects
 */
export function getIqObjects(uids=false) {
    if(uids === false) {
        return getDevices().concat(getFieldbuses()).concat(getClfbs());
    }

    let iqObjects = getDevices().filter((item) => {
        return uids.includes(item.uid);
    }).concat(getFieldbuses().filter((item) => {
        return uids.includes(item.uid);
    })).concat(getClfbs().filter((item) => {
        return uids.includes(item.uid);
    }));

    return iqObjects;
}

/**
 * Get an IQ Object by uid
 * @param {string} uid
 * @returns {false|object} - The Iq Object, or false if it can't be found
 */
export function getIqObject(uid) {
    for(let i = 0; i < state.devices.length; i++) {
        const device = state.devices[i];
        if(device.uid === uid) {
            return device;
        }
    }
    for(let i = 0; i < state.fieldbuses.length; i++) {
        const fieldbus = state.fieldbuses[i];
        if(fieldbus.uid === uid) {
            return fieldbus;
        }
    }
    for(let i = 0; i < state.clfbs.length; i++) {
        const clfb = state.clfbs[i];
        if(clfb.uid === uid) {
            return clfb;
        }
    }
    return false;
}

/**
 * Returns a list of devices with the given filter options, flagging each
 * device with a _show boolean property
 * @param {string} mode
 * @param {boolean|null} hasWarnings
 * @param {boolean|null} hasErrors
 * @returns {array}
 */
export function filterDevices(mode='', hasWarnings=null, hasErrors=null){
    return getDevices().map(dev => {
        let device = Helpers.clone(dev);
        device._show = (
            (mode !== '' && device.status.mode === mode) ||
            (mode === 'disabled' && device.status.mode === 'disconnected') ||
            (hasWarnings !== null && device.status.warnings === hasWarnings) ||
            (hasErrors !== null && device.status.errors === hasErrors)
        );
        return device;
    });
}


/**
 * Returns a list of fieldbuses with the given filter options, flagging each
 * fieldbus with a _show boolean property
 * @param {string} mode
 * @param {boolean|null} hasErrors
 * @returns {array}
 */
export function filterFieldbuses(mode='', hasWarnings=null, hasErrors=null){
    return getFieldbuses().map(fb => {
        let fieldbus = Helpers.clone(fb);

        fieldbus._show = (
            (mode !== '' && fieldbus.status.mode === mode) ||
            (mode === 'disabled' && fieldbus.status.mode === 'stopped') ||
            (mode === 'standby' && Helpers.arrayContains(fieldbus.status.mode, ['waiting', 'starting'])) ||
            (hasWarnings !== null && fieldbus.status.warnings === hasWarnings) ||
            (hasErrors !== null && fieldbus.status.errors === hasErrors)
        );
        return fieldbus;
    });
}


/**
 * Returns a list of clfbs with the given filter options, flagging each
 * fieldbus with a _show boolean property
 * @param {string} mode
 * @param {boolean|null} hasErrors
 * @returns {array}
 */
export function filterClfbs(mode='', hasWarnings=null, hasErrors=null){
    return getClfbs().map(cb => {
        let clfb = Helpers.clone(cb);

        clfb._show = (
            (mode !== '' && clfb.status.mode === mode) ||
            (hasWarnings !== null && clfb.status.warnings === hasWarnings) ||
            (hasErrors !== null && clfb.status.errors === hasErrors)
        );
        return clfb;
    });
}

/**
 * Get a short device description from it's uid
 * @param {string} uid
 * @returns {object|false} - false or the device object
 */
export function getDevice(uid) {
    for(const i in state.devices){
        if(state.devices[i].uid === uid){
            return state.devices[i];
        }
    }
    return false;
}

/**
 * Update the state of a device
 * @param {object} data - Device data
 */
export function updateDevice(data) {
    for(const i in state.devices){
        if(state.devices[i].uid === data.uid){
            data._meta = {'type': 'device'};
            state.devices[i] = data;
            Debug.updateState(state);
            return;
        }
    }
}

/**
 * Update the state of a fieldbus
 * @param {object} data - Fieldbus data
 */
export function updateFieldbus(data) {
    for(const i in state.fieldbuses){
        if(state.fieldbuses[i].uid === data.uid){
            data._meta = {'type': 'fieldbus'};
            state.fieldbuses[i] = data;
            Debug.updateState(state);
            return;
        }
    }
}


/**
 * Update the state of a Clfb
 * @param {object} data - Clfb data
 */
export function updateClfb(data) {
    for(const i in state.clfbs){
        if(state.clfbs[i].uid === data.uid){
            data._meta = {'type': 'clfb'};
            state.clfbs[i] = data;
            Debug.updateState(state);
            return;
        }
    }
}

/**
 * Handling of object removal
 */
export function objectRemoved(msg) {
    if(!msg.hasOwnProperty('data') || !msg.data.hasOwnProperty('uid')) {
        return false;
    }

    const iqObject = getIqObject(msg.data.uid);

    if(iqObject === false) {
        return false;
    }

    if(Helpers.isDevice(iqObject)) {
        updateDeviceList();
    } else if(Helpers.isFieldbus(iqObject)) {
        updateFieldbusList();
    } else if(Helpers.isClfb(iqObject)) {
        updateClfbList();
    }
}

/**
 * Retrieve the list of global permissions from the state object
 * @returns {object} - the list of permissions
 */
export function getGlobalPermissions() {
    return state.globalPermissions;
}

/**
 * Retrieve the global state
 * @returns {object} - the global state object
 */
export function getManagerStatus() {
    return state.managerStatus;
}

function emitTimeUpdateSignal() {
    Signals.emit({
        'tag': 'internal/minute-passed',
        'data': {
            'time': state.time.current,
        }
    });
}

/**
 * Process a time change
 * @param {number|string} newTime - the new time, as a unix timestamp in millisconds
 *     since Jan 1st 1970
 */
function setTime(newTime) {
    const oldTime = state.time.current;

    if(typeof newTime === 'string') {
        state.time.current = parseInt(newTime);
    } else {
        state.time.current = newTime;
    }

    // Check if minute has passed
    if(Math.floor(oldTime / 60000) !== Math.floor(state.time.current / 60000)) {
        emitTimeUpdateSignal();
    }
}

function processTimeMessage(msg) {
    if(!msg.hasOwnProperty('data') || !msg.data.hasOwnProperty('time')) {
        return;
    }
    setTime(msg.data.time);
}

/**
 * Retrieve the current system time
 * @return {number} - the current time in milliseconds
 */
export function getTime() {
    return state.time.current;
}

/**
 * Return true if 24 hours time representation is preferred
 * @returns {boolean}
 */
export function get24HoursIsPreferred() {
    return state.time.use24hours;
}

/**
 * Retrieve the client's (not server's) timezone offset
 * @return {number} - the timezone offset i.e: -120 is UTC+2
 */
export function getTimeZoneOffset() {
    return state.time.timeZoneOffset;
}

/**
 * Set the client's timezone offset
 */
export function setTimeZoneOffset(offset) {
    state.time.timeZoneOffset = parseInt(offset);
    updatePreferences();
    emitTimeUpdateSignal();
}

/**
 * Set whether the client uses 24hours notation or am/pm
 */
export function setUse24hours(use24hours) {
    state.time.use24hours = use24hours;
    updatePreferences();
    emitTimeUpdateSignal();
}

/**
 * Get a pretty time string
 * @param {boolean} useLocalTimezone - whether to use the local client's timezone
 *     for formatting the time (defaults to true)
 * @return {string} - a prettified time string based on the locale
 */
export function getPrettyTime(useLocalTimezone=true) {
    if(useLocalTimezone) {
        return Helpers.formatTime(
            state.time.current,
            state.time.timeZoneOffset,
            state.time.use24hours
        );
    } else {
        return Helpers.formatTime(
            state.time.current,
            0,
            state.time.use24hours
        );
    }
}

/**
 * @param {array} order
 */
export function setCustomObjectOrder(order) {
    state.customObjectOrder = order;
    updatePreferences();
}

export function setObjectOrderMode(orderMode) {
    state.objectOrderMode = orderMode;
    updatePreferences();
}

/**
 * @returns {array} - list of ordered IQ Objects
 */
export function getOrderedIqObjects(iqObjects=(getDevices().concat(getFieldbuses()).concat(getClfbs()))) {
    iqObjects.sort((a, b) => {
        if(state.objectOrderMode === 'manual') {
            return (
                state.customObjectOrder.indexOf(a.uid) -
                state.customObjectOrder.indexOf(b.uid)
            );
        } else if(state.objectOrderMode === 'name') {
            return a.name < b.name ? -1 : 1;
        } else if(state.objectOrderMode === 'type') {
            const typeA = Helpers.iqObjectType(a);
            const typeB = Helpers.iqObjectType(b);
            if(typeA !== typeB) {
                return Helpers.iqObjectType(a) < Helpers.iqObjectType(b) ? -1 : 1;
            } else {
                return a.name < b.name ? -1 : 1;
            }
        } else if(state.objectOrderMode === 'position') {
            let posA = '';
            let posB = '';
            if(a.hasOwnProperty('position')) {
                posA = a.position;
            }
            if(b.hasOwnProperty('position')) {
                posB = b.position;
            }
            return posA < posB ? -1 : 1;
        }
    });

    return iqObjects;
}

/**
 * @returns {string} - The order mode: 'manual', 'type', 'position' or 'name'
 */
export function getObjectOrderMode() {
    return state.objectOrderMode;
}

export function getCustomObjectOrder() {
    return state.customObjectOrder;
}

export function getRecordedTests() {
    return state.recordedTests;
}

export function setRecordedTests(recordedTests) {
    state.recordedTests = recordedTests;
    updatePreferences();
}
