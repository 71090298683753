/**
 * Related Devices view module.
 * @module relatedview
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Constants from '../core/constants';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Debug from '../core/debug';
import * as Signals from '../core/signals';
import * as State from '../core/state';
import * as Messages from '../core/messages';

export class RelatedView {
    /**
     * @param {Object} iqObject
     */
    constructor(iqObject) {
        Debug.log('Setting up related view');
        this.iqObject = iqObject;
        this.iqObjectType = Helpers.iqObjectType(iqObject);
        this.listenerIds = [];
        this.actionHandlerIds = [];
        this.shown = false;
        this.currentView = 'list';
        this.waitingForChange = false;

        this.listenerIds.push(Signals.addListener(
            'internal/new-device-list',
            this.newObjectList.bind(this)
        ));

        this.listenerIds.push(Signals.addListener(
            'internal/new-fieldbus-list',
            this.newObjectList.bind(this)
        ));

        this.listenerIds.push(Signals.addListener(
            'internal/new-clfb-list',
            this.newObjectList.bind(this)
        ));

        this.listenerIds.push(Signals.addListener(
            'object/relatedchange',
            this.relatedChange.bind(this)
        ));
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    updateRelatedCount() {
        if(this.iqObjectType === 'device') {
            $('span.related-count').html(this.iqObject.relatedObjects.length);
        } else if(this.iqObjectType === 'fieldbus') {
            $('span.related-count').html(this.iqObject.modules.length + 1);
        } else if(this.iqObjectType === 'clfb'){
            let relatedCount = 0;
            if(this.iqObject.settings.inputUid.currentValue) {
                relatedCount++;
            }
            if(this.iqObject.settings.outputUid.currentValue) {
                relatedCount++;
            }
            relatedCount += this.iqObject.relatedObjects.length;
            $('span.related-count').html(relatedCount.toString());
        }
    }

    newObjectList() {
        this.updateRelatedCount();
        if(this.currentView === 'list') {
            this.showList();
        } else if(this.currentView === 'add') {
            this.showAddRelated(false);
        } else if(this.currentView === 'delete') {
            this.showDeleteRelated();
        }
    }

    getRelatedUids(skipClfbOutput=false) {
        let uids = [];
        if(this.iqObjectType === 'device' || this.iqObjectType === 'clfb') {
            for(let i = 0; i < this.iqObject.relatedObjects.length; i++){
                uids.push(this.iqObject.relatedObjects[i].uid);
            }
        } else if(this.iqObjectType === 'fieldbus') {
            const sortedModules = this.iqObject.modules.sort((a, b) => {
                return a.slot - b.slot;
            });

            for(let i of sortedModules) {
                uids.push(i.uid);
            }
        }

        if(Helpers.isClfb(this.iqObject)) {
            if(this.iqObject.settings.inputUid.currentValue) {
                uids.push(this.iqObject.settings.inputUid.currentValue);
            }
            if(this.iqObject.settings.outputUid.currentValue && !skipClfbOutput) {
                uids.push(this.iqObject.settings.outputUid.currentValue);
            }
        }

        return uids;
    }

    waitForRelatedChange() {
        this.waitingForChange = true;
        this.waitingForChangeId = setTimeout(() => {
            if(this.waitingForChange === true) {
                this.waitingForChange = false;
                this.waitingForChangeId = -1;
                $('div.waiting-for-related-change').remove();
                this.newObjectList();
            }
        }, 5000);
    }

    relatedChange(msg) {
        if(msg.data.uid === this.iqObject.uid) {
            this.waitingForChange = false;
            clearTimeout(this.waitingForChangeId);

            if(this.iqObjectType === 'device') {
                Api.getDevice(this.iqObject.uid).then((device) => {
                    let oldUids = [];
                    let newUids = [];
                    for(let i = 0; i < this.iqObject.relatedObjects.length; i++) {
                        oldUids.push(this.iqObject.relatedObjects[i].uid);
                    }
                    for(let i = 0; i < device.relatedObjects.length; i++) {
                        newUids.push(device.relatedObjects[i].uid);
                    }
                    if(!Helpers.arraysContainSame(oldUids, newUids)) {
                        this.iqObject.relatedObjects = device.relatedObjects;
                        this.newObjectList();
                    }
                }).catch((e) => {
                    Messages.addError(104, _('Failed to retrieve device'));
                });
            } else if(this.iqObjectType === 'fieldbus') {
                Api.getFieldbus(this.iqObject.uid).then((fieldbus) => {
                    let oldUids = [];
                    let newUids = [];
                    for(let i = 0; i < this.iqObject.modules.length; i++) {
                        oldUids.push(this.iqObject.modules[i].uid);
                    }
                    for(let i = 0; i < fieldbus.modules.length; i++) {
                        newUids.push(fieldbus.modules[i].uid);
                    }
                    if(!Helpers.arraysContainSame(oldUids, newUids)) {
                        this.iqObject.modules = fieldbus.modules;
                        this.newObjectList();
                    }
                }).catch((e) => {
                    Messages.addError(105, _('Failed to retrieve fieldbus'));
                });
            } else if(this.iqObjectType === 'clfb') {
                Api.getClfb(this.iqObject.uid).then((clfb) => {
                    this.updateRelatedCount();
                    this.iqObject = clfb;
                    this.newObjectList();
                }).catch((e) => {
                    Messages.addError(106, _('Failed to retrieve CLFB'));
                });
                return;
            }
        }
    }

    toggleRelated(actionId, args=[]) {
        window.scrollTo(0, 0);
        let elem = $('section.related-objects');

        if(!this.shown) {
            this.shown = true;
            $(elem).attr('aria-expanded', 'true');
            $('section.related-objects').css('top', '180px');
            $('section.related-objects a.toggle-related').html('<span class="icon-down-open"></span>');
        } else {
            this.shown = false;
            $(elem).attr('aria-expanded', 'false');
            $('section.related-objects').css('top', 'calc(100% - 50px)');
            $('section.related-objects a.toggle-related').html('<span class="icon-up-open"></span>');
        }
    }

    /**
     * @param {boolean} interactive - whether this was called interactively, by
     *     user request
     */
    showAddRelated(interactive=true) {
        if(interactive && this.iqObjectType === 'fieldbus') {
            const obj = State.getIqObject(this.iqObject.uid);
            if(obj !== false && obj.status.mode === 'running') {
                return Messages.addError(127, _('You can not add a related object to a fieldbus while the fieldbus is running'));
            }
        }

        if(!this.shown) {
            this.toggleRelated(this.iqObject.uid);
        }

        this.currentView = 'add';

        let iqObjects = [];
        let relatedUids = this.getRelatedUids();
        let isClfbOutput = false;

        iqObjects = State.getIqObjects().filter((item) => {
            if(relatedUids.indexOf(item.uid) !== -1) {
                return false;
            }

            if(
                item._meta.type === 'clfb' &&
                relatedUids.includes(item.uid) &&
                Helpers.deviceHasRole(this.iqObject, 'clfb-output')
            ) {
                isClfbOutput = true;
                return false;
            }

            if(item.uid === this.iqObject.uid) {
                return false;
            }

            if(item._meta.type === 'fieldbus') {
                if(this.iqObjectType === 'fieldbus') {
                    return false;
                }
                if(this.iqObjectType === 'clfb') {
                    return true;
                }
            }

            if(this.iqObjectType === 'clfb') {
                if(
                    !this.iqObject.settings.inputUid.currentValue &&
                    this.iqObject.settings.outputUid.currentValue &&
                    Helpers.deviceHasRole(item, 'clfb-input')
                ) {
                    return true;
                } else if(!this.iqObject.settings.outputUid.currentValue && Helpers.deviceHasRole(item, 'clfb-output')) {
                    return true;
                } else {
                    return false;
                }
            }

            if(
                this.iqObjectType === 'fieldbus' &&
                Helpers.iqObjectType(item) !== 'device' &&
                Helpers.iqObjectType(item) !== 'clfb'
            ) {
                return false;
            }

            if(this.iqObjectType === 'device' && Helpers.iqObjectType(item) === 'clfb') {
                if(!Helpers.deviceHasRole(this.iqObject, 'clfb-input')) {
                    return false;
                }

            }

            if(this.iqObjectType === 'device' && Helpers.iqObjectType(item) === 'device') {
                return false;
            }

            return true;
        });

        let addingNewClfbAllowed = (
            Helpers.isDevice(this.iqObject) &&
            !isClfbOutput &&
            Helpers.deviceHasRole(this.iqObject, 'clfb-output')
        );

        if(this.iqObjectType === 'device' &&
            Helpers.deviceHasRole(this.iqObject, 'clfb-output')
        ) {
            for(let relatedObject of this.iqObject.relatedObjects) {
                if(relatedObject.type === 'clfb') {
                    addingNewClfbAllowed = false;
                }
            }
        }

        let template = Gui.renderToString('related-add.html', {
            iqObject: this.iqObject,
            iqObjects: iqObjects,
            addingNewClfbAllowed: addingNewClfbAllowed,
        });

        $('section.related-objects-panel').html(template);
    }

    /**
     * @param {boolean} interactive - whether this was called interactively, by
     *     user request
     */
    showDeleteRelated(interactive=true) {
        if(interactive && this.iqObjectType === 'fieldbus') {
            const obj = State.getIqObject(this.iqObject.uid);
            if(obj !== false && obj.status.mode === 'running') {
                return Messages.addError(128, _('You can not remove a related object from a fieldbus while the fieldbus is running'));
            }
        }

        if(!this.shown) {
            this.toggleRelated(this.iqObject.uid);
        }

        this.currentView = 'delete';

        let uids = this.getRelatedUids(true);
        let iqObjects = State.getIqObjects(uids);

        iqObjects = iqObjects.concat(this.getUnknownObjects());

        // Sort iqObjects so we match the slot ordering from fieldbuses
        iqObjects = iqObjects.sort((a, b) => {
            return uids.indexOf(a.uid) - uids.indexOf(b.uid);
        });

        // If we are on a fieldbus screen we want to include the slots, so we
        // add slot numbers to all iqObjects and sort them accordingly
        if(Helpers.isFieldbus(this.iqObject)) {
            let slots = {};
            for(let module of this.iqObject.modules) {
                slots[module.uid] = module.slot;
            }
            for(let iqObj of iqObjects) {
                iqObj._meta.slot = slots[iqObj.uid];
            }

            iqObjects = iqObjects.sort((a, b) => {
                return a._meta.slot - b._meta.slot;
            });
        }

        // You can't delete a CLFB output, so we remove it from the list
        if(Helpers.isClfb(this.iqObject) && this.iqObject.settings.outputUid.currentValue) {
            iqObjects = Helpers.clone(iqObjects);
            let newList = [];
            for(let obj of iqObjects) {
                if(obj.uid !== this.iqObject.settings.outputUid.currentValue) {
                   newList.push(obj);
                }
            }
            iqObjects = newList;
        }

        let template = Gui.renderToString('related-delete.html', {
            iqObject: this.iqObject,
            iqObjects: iqObjects,
        });

        $('section.related-objects-panel').html(template);
    }

    addRelated(actionId, args, elem) {
        this.currentView = 'select-slot';
        Gui.enableLoadingIcon(elem);
        $(elem).prop('disabled', true);
        let uid = args.param;
        let target = State.getIqObject(uid);

        if(target === false) {
            Messages.addError(107, _('Invalid related object target'));
            return;
        }

        if(Helpers.isFieldbus(this.iqObject) || Helpers.isFieldbus(target)) {
            // Target or source is a fieldbus, so the user has to pick a slot
            const fieldbus = Helpers.isFieldbus(this.iqObject) ? this.iqObject : target;
            const connectee = Helpers.isFieldbus(this.iqObject) ? target : this.iqObject;

            if(fieldbus.status.mode === 'running') {
                Gui.disableLoadingIcon(elem);
                $(elem).prop('disabled', false);
                return Messages.addError(127, _('You can not add a related object to a fieldbus while the fieldbus is running'));
            }

            let setupSlotSelection = (iqObject, fieldbus, connectee) => {
                let modules = Helpers.clone(fieldbus.modules);
                let occupiedSlots = [];

                for(let module of modules) {
                    occupiedSlots.push(module.slot);
                    module.obj = State.getIqObject(module.uid);
                    if(module.obj === false) {
                        module.obj = {
                            uid: module.uid,
                            _meta: {
                                type: 'unknown',
                                slot: module.slot,
                            },
                        };
                    }
                }

                for(let i = 3; i <= Constants.MAX_RELATED_OBJECTS; i++) {
                    if(!occupiedSlots.includes(i)) {
                        modules.push({
                            slot: i,
                            uid: false,
                            obj: false
                        });
                    }
                }

                modules.sort((a, b) => {
                    return a.slot - b.slot;
                });

                let template = Gui.renderToString('related-select-slot.html', {
                    iqObject: this.iqObject,
                    fieldbus: fieldbus,
                    connectee: connectee,
                    modules: modules,
                });

                $('section.related-objects-panel').html(template);
            };

            // if the fieldbus is already the full object then we can show the
            // "select slot" screen immediately, otherwise we first have to
            // retrieve it
            if(fieldbus.hasOwnProperty('modules')) {
                setupSlotSelection(this.iqObject, fieldbus, connectee);
            } else {
                Debug.log('Retrieve fieldbus with uid ' + fieldbus.uid);
                Api.getFieldbus(fieldbus.uid).then((fieldbus) => {
                    setupSlotSelection(this.iqObject, fieldbus, connectee);
                }).catch((e) => {
                    Messages.addError(108, _('Failed to retrieve the fieldbus'));
                    Debug.log(e);
                });
            }
        } else if(Helpers.isClfb(this.iqObject)) {
            Api.getClfb(this.iqObject.uid).then((clfb) => {
                this.waitForRelatedChange();
                if(!this.iqObject.settings.inputUid.currentValue && Helpers.deviceHasRole(target, 'clfb-input')) {
                    clfb.settings.inputUid.currentValue = uid;
                } else if (!this.iqObject.settings.outputUid.currentValue && Helpers.deviceHasRole(target, 'clfb-output')) {
                    clfb.settings.outputUid.currentValue = uid;
                }

                Api.updateClfbSettings(this.iqObject.uid, clfb.settings).then(() => {
                    this.iqObject.settings = clfb.settings;
                    this.currentView = 'list';
                    this.newObjectList();
                }).catch((e) => {
                    Messages.addError(109, _('Failed to update the CLFB settings'));
                    Debug.log(e);
                });

            }).catch((e) => {
                Messages.addError(110, _('Failed to retrieve the CLFB'));
                Debug.log(e);
            });
        } else if(Helpers.isClfb(target)) {
            this.waitForRelatedChange();
            this.currentView = 'list';
            this.newObjectList();
            Api.getClfb(target.uid).then((clfb) => {
                if(Helpers.deviceHasRole(this.iqObject, 'clfb-input')) {
                    clfb.settings.inputUid.currentValue = this.iqObject.uid;
                } else if (Helpers.deviceHasRole(this.iqObject, 'clfb-output')) {
                    clfb.settings.outputUid.currentValue = this.iqObject.uid;
                }

                Api.updateClfbSettings(target.uid, clfb.settings).then(() => {
                    this.currentView = 'list';
                    this.newObjectList();
                }).catch((e) => {
                    Debug.log(e);
                    Messages.addError(111, _('Failed to update the CLFB settings'));
                });

            }).catch((e) => {
                Debug.log(e);
                Messages.addError(112, _('Failed to retrieve the CLFB'));
            });
        }
    }

    selectSlot(actionId, args, elem) {
        $('a.empty-slot').addClass('no-link');
        $('span', elem).addClass('has-loading-icon');
        const fieldbusUid = Helpers.data(elem, 'fieldbus').toString();
        const connecteeUid = Helpers.data(elem, 'connectee').toString();
        const slot = parseInt(Helpers.data(elem, 'slot').toString());
        this.currentView = 'list';
        Api.fieldbusMap(fieldbusUid, connecteeUid, slot).then(() => {
            this.waitForRelatedChange();
        }).catch((e) => {
            Messages.addError(113, _('Failed to update the Fieldbus Map'));
        });
    }

    deleteRelated(actionId, args, elem) {
        Gui.enableLoadingIcon(elem);
        $(elem).prop('disabled', true);
        let targetObject = State.getIqObject(args.param);
        let targetType = Helpers.iqObjectType(targetObject);
        let fieldBusUid = -1;
        let connectedUid = -1;

        if(this.iqObjectType === 'fieldbus' || targetType === 'fieldbus') {
            const fieldbusUid = (
                this.iqObjectType === 'fieldbus' ?
                this.iqObject.uid : targetObject.uid
            );

            const obj = State.getIqObject(fieldbusUid);
            if(obj !== false && obj.status.mode === 'running') {
                Gui.disableLoadingIcon(elem);
                $(elem).prop('disabled', false);
                return Messages.addError(
                    128,
                    _('You can not delete a related object from a fieldbus while the fieldbus is running')
                );
            }
        }

        if(this.iqObjectType === 'device' && targetType === 'fieldbus') {
            fieldBusUid = args.param;
            connectedUid = this.iqObject.uid;
        } else if(this.iqObjectType === 'fieldbus') {
            fieldBusUid = this.iqObject.uid;
            connectedUid = args.param;
        } else if(this.iqObjectType === 'clfb' || targetType === 'clfb') {
            let clfbUid = -1;
            if(targetType === 'device') {
                connectedUid = args.param;
                clfbUid = this.iqObject.uid;
            } else if(targetType === 'fieldbus') {
                fieldBusUid = args.param;
                clfbUid = this.iqObject.uid;
                connectedUid = this.iqObject.uid;
            } else if(targetType === 'clfb') {
                connectedUid = this.iqObject.uid;
                clfbUid = args.param;
            } else if(targetType === 'unknown' && this.iqObjectType === 'clfb') {
                connectedUid = args.param;
                clfbUid = this.iqObject.uid;
            } else {
                Messages.addError(126, _('Can not delete unknown related object'));
                return;
            }

            Api.getClfb(clfbUid.toString()).then((clfb) => {
                if(clfb.settings.inputUid.currentValue === connectedUid) {
                    clfb.settings.inputUid.currentValue = '';
                }

                if(clfb.settings.outputUid.currentValue === connectedUid) {
                    Messages.addError(123, _('Can not delete CLFB output'));
                    return;
                }

                Api.updateClfbSettings(clfbUid, clfb.settings).then(() => {
                    this.waitForRelatedChange();
                    this.iqObject.settings = clfb.settings;
                    this.currentView = 'list';
                    this.showList();
                }).catch((e) => {
                    Messages.addError(114, _('Failed to update the CLFB settings'));
                    Debug.log(e);
                });

            }).catch((e) => {
                Debug.log(e);
                Messages.addError(115, _('Failed to retrieve the CLFB'));
            });


            if(fieldBusUid !== -1) {
                Api.deleteFieldbusMap(fieldBusUid.toString(), connectedUid.toString()).then(() => {

                }).catch((e) => {
                    this.newObjectList();
                });
            }

            return;
        }

        Api.deleteFieldbusMap(fieldBusUid.toString(), connectedUid.toString()).then(() => {

        }).catch((e) => {
            this.newObjectList();
        });
    }

    addNewClfb(actionId, args, elem) {
        $(elem).addClass(['has-loading-icon', 'no-link']);
        this.waitForRelatedChange();
        Api.createClfb(this.iqObject.uid).then((clfb) => {
            Debug.recordEvent('new-clfb', {uid: clfb.uid});
            this.currentView = 'list';
            this.showList();
        }).catch((err) => {
            Messages.addError(118, _('An error occured while creating a new CLFB'));
        });
    }

    registerActionHandlers() {
        this.actionHandlerIds.push(
            Actions.registerActionHandler(
               this.iqObject.uid,
               'toggle-add-related',
               this.showAddRelated.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
               this.iqObject.uid,
               'toggle-delete-related',
               this.showDeleteRelated.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                this.iqObject.uid, 'toggle-related', this.toggleRelated.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                this.iqObject.uid, 'add-related', this.addRelated.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                this.iqObject.uid, 'delete-related', this.deleteRelated.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                this.iqObject.uid, 'show-related', this.showList.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                this.iqObject.uid, 'select-slot', this.selectSlot.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                this.iqObject.uid, 'add-new-clfb', this.addNewClfb.bind(this)
            )
        );

    }

    /**
     * Retrieve a list of related objects that are linked to the current iqObject, but that are unknown in the system.
     * @returns {array} List of unknown objects in the generic iqObject form
     */
    getUnknownObjects() {
        let unknownObjects = [];

        if(Helpers.isDevice(this.iqObject)) {
            for(let i = 0; i < this.iqObject.relatedObjects.length; i++) {
                const uid = this.iqObject.relatedObjects[i].uid;
                let relatedObj = State.getIqObject(uid);
                if(relatedObj === false) {
                    unknownObjects.push({
                        uid: uid,
                        _meta: {
                            'type': 'unknown',
                            'slot': -1, // Slot is unknown when linked to a device
                        },
                    });
                }
            }
        } else if(Helpers.isFieldbus(this.iqObject)) {
            const sortedModules = this.iqObject.modules.sort((a, b) => {
                return a.slot - b.slot;
            });

            for(let i of sortedModules) {
                const uid = i.uid;
                let relatedObj = State.getIqObject(uid);
                if(relatedObj === false) {
                    unknownObjects.push({
                        uid: uid,
                        _meta: {
                            'type': 'unknown',
                            'slot': i.slot,
                        },
                    });
                }
            }
        } else if(Helpers.isClfb(this.iqObject)) {
            let unknownUids = [];
            const inputUid = this.iqObject.settings.inputUid.currentValue;
            const outputUid = this.iqObject.settings.outputUid.currentValue;

            let uidsToCheck = [];
            if(inputUid) {
                uidsToCheck.push(inputUid);
            }

            if(outputUid) {
                uidsToCheck.push(outputUid);
            }

            for(let item of this.iqObject.relatedObjects) {
                if(uidsToCheck.indexOf(item.uid) !== -1) {
                    continue;
                }
                uidsToCheck.push(item.uid);
            }

            for(let uid of uidsToCheck) {
                if(State.getIqObject(uid) === false) {
                    unknownObjects.push({
                        uid: uid,
                        _meta: {
                            'type': 'unknown',
                            'slot': -1,
                        },
                    });
                }
            }

        }

        return unknownObjects;
    }

    showList() {
        this.currentView = 'list';
        let uids = this.getRelatedUids();
        let iqObjects = State.getIqObjects(uids);

        iqObjects = iqObjects.concat(this.getUnknownObjects());

        // Sort iqObjects so we match the slot ordering from fieldbuses
        iqObjects = iqObjects.sort((a, b) => {
            return uids.indexOf(a.uid) - uids.indexOf(b.uid);
        });

        // Add slot meta attribute to related objects if this is a fieldbus
        if(Helpers.isFieldbus(this.iqObject)) {
            let slotMap = {};
            for(let module of this.iqObject.modules) {
                slotMap[module.uid] = module.slot;
            }
            iqObjects.forEach((iqo) => {
                iqo._meta.slot = slotMap[iqo.uid];
            });
        }

        let template = Gui.renderToString('related.html', {
            iqObject: this.iqObject,
            iqObjects: iqObjects,
            waitingForChange: this.waitingForChange,
        });

        $('section.related-objects-panel').html(template);
    }

    render() {
        this.showList();
        this.registerActionHandlers();
    }
}
